import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import { setCreated, setRemoveAll, setUploadProgress } from "../slices/AlbumSlice";

export const albumApi = createApi({
	reducerPath: "albumApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token");
			headers.set("authorization", token);
			return headers;
		},
	}),
	tagTypes: ["Album"],
	endpoints: (builder) => ({
		createSong: builder.mutation({
			queryFn: async ({ album_id, title, file, videoAudioFile, description }, api) => {
				const formData = new FormData();
				formData.append("album_id", album_id);
				formData.append("title", title);
				formData.append("thumbnail", file);
				formData.append("file", videoAudioFile);
				formData.append("description", description);
				try {
					const result = await axios.post("/album/create-song", formData, {
						onUploadProgress: (upload) => {
							let uploadedProgress = Math.round((100 * upload.loaded) / upload.total);
							api.dispatch(setUploadProgress(uploadedProgress));
						},
					});
					const { data } = result;
					if (data.status === true) {
						api.dispatch(setCreated(true));
						api.dispatch(setRemoveAll());
					}
					return { data: result.data };
				} catch (err) {
					return {
						error: {
							status: err.response?.status,
							data: err.response?.data || err.message,
						},
					};
				}
			},
		}),
		deleteSong: builder.mutation({
			queryFn: async (id, api) => {
				try {
					const result = await axios.delete(`album/deleteSong/${id}`);
					const { data } = result;
					if (data.status === true) {
						toast.success("Song delete successfully !");
						api.dispatch(setCreated(true));
					}
					return { data: result.data };
				} catch (err) {
					return {
						error: {
							status: err.response?.status,
							data: err.response?.data || err.message,
						},
					};
				}
			},
		}),
		getTopAlbums: builder.query({
			query: (offsetTopAlbum) => `album/getLatestAlbumUser?offset=${offsetTopAlbum}`,
			providesTags: ["Album"],
		}),
		getLatestAlbums: builder.query({
			query: (offsetLatestAlbum) => `album/getLatestAlbumUser?offset=${offsetLatestAlbum}`,
			providesTags: ["Album"],
		}),
		getAlbums: builder.query({
			query: ({ id, offsetAlbum }) => `album/get?offset=${offsetAlbum}&user_id=${id}`,
			providesTags: ["Album"],
		}),
		createAlbum: builder.mutation({
			queryFn: async ({ title, file, description }, api) => {
				const formData = new FormData();
				formData.append("cover_photo", file);
				formData.append("title", title);
				formData.append("description", description);

				try {
					const result = await axios.post("/album/create", formData, {
						onUploadProgress: (upload) => {
							let uploadedProgress = Math.round((100 * upload.loaded) / upload.total);
							api.dispatch(setUploadProgress(uploadedProgress));
						},
					});
					const { data } = result;
					if (data.status === true) {
						api.dispatch(setCreated(true));
						api.dispatch(setRemoveAll());
					}
					return { data: result.data };
				} catch (err) {
					return {
						error: {
							status: err.response?.status,
							data: err.response?.data || err.message,
						},
					};
				}
			},
			invalidatesTags: ["Album"],
		}),
		deleteAlbum: builder.mutation({
			queryFn: async (id, api) => {
				try {
					const result = await axios.delete(`album/deleteAlbum/${id}`);
					const { data } = result;
					if (data.status === true) {
						api.dispatch(setCreated(true));
						toast.success("Album delete successfully !");
					}
					return { data: result.data };
				} catch (err) {
					return {
						error: {
							status: err.response?.status,
							data: err.response?.data || err.message,
						},
					};
				}
			},
			invalidatesTags: ["Album"],
		}),
	}),
});

export const {
	useCreateSongMutation,
	useGetAlbumsQuery,
	useCreateAlbumMutation,
	useDeleteSongMutation,
	useDeleteAlbumMutation,
	useGetTopAlbumsQuery,
	useGetLatestAlbumsQuery,
} = albumApi;
