import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearch } from "../../Redux/slices/TempSlice";
import axios from "../../components/Hooks/axios";
import useUserValidate from "../../components/Hooks/useUserValidate";
import leftImg2 from "../../images/Rectangle 79.png";
import leftImg1 from "../../images/Rectangle 80 (1).png";
import searchIcon from "../../images/Vector (2).png";
import Header from "../header.component";

function Learning() {
	useUserValidate();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [ClassesData, setClassesData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoading2, setIsLoading2] = useState(false);
	const [offsetTopAlbum, setOffsetTopAlbum] = useState(0);
	const [enrolledCourses, setEnrolledCourses] = useState([]);

	useEffect(() => {
		if (userId === "") return;
		setIsLoading2(true);
		axios
		.get(`/class/getEnrollCourse/${userId}`)
		.then(({ data }) => {
			if (data.status) {
				setEnrolledCourses(data.data[0].enrolled);
				setIsLoading2(false);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`/class/getClass?offset=${offsetTopAlbum}`)
			.then(({ data }) => {
				if (data.status === true) {
					setClassesData(data.data);
					setIsLoading(false);
				}
			})
			.catch((e) => console.log(e));
	}, [offsetTopAlbum]);

	return (
		<div className="HomeMainContainer">
			<Header />

			<div className="UserLearning">
				<div className="LearningHeading">
					<h5>
						<span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
							◄
						</span>{" "}
						Learning
					</h5>
					<img
						src={searchIcon}
						className="album-search"
						alt="Search"
						onClick={() => dispatch(setSearch({ search: true, type: "courseTitle" }))}
					/>
				</div>

				<div className="LearningUserCarousel" style={{ display: "none" }}>
					<div>
						<img src={leftImg1} alt="" />
					</div>

					<div>
						<img src={leftImg2} alt="" />
					</div>
				</div>

				<div className="EnrollCourseMainContainer">
					<h5>Continue Learning</h5>
					{isLoading2 ? (
						<div style={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<RotatingLines
								strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="100"
								visible={true}
							/>
						</div>
					) : (
						<div className="EnrolledCourseContainer">
							{enrolledCourses.map((item, index) => (
								<div
									key={index}
									className="EnrolledCourseBox"
									onClick={() => navigate("/learning/CourseDetails/" + item._id)}>
									<div className="EnrolledCourseBoxImageCont">
										<img src={item.coverpic} alt="" />
									</div>
									<div className="EnrolledCourseBoxTextCont">
										<h6>{item.courseTitle}</h6>
										<p>
											{item.courseDescription.slice(0, 100)}
											{item.courseDescription.length > 100 && ". . . ."}
										</p>
										<span>
											{item.createdAt.split("T")[0] + " at " + item.createdAt.split("T")[1].split(".")[0]}
										</span>
									</div>
								</div>
							))}
						</div>
					)}
					{/* <div className="AlbumPagination">
						<button disabled={offsetTopAlbum === 0 && true} onClick={() => setOffsetTopAlbum(offsetTopAlbum - 10)}>
							{"<"} Prev
						</button>
						<button
							disabled={ClassesData?.length < 10 && true}
							onClick={() => setOffsetTopAlbum(offsetTopAlbum + 10)}>
							Next {">"}
						</button>
					</div> */}
				</div>

				<div className="LearningClassMainContainer">
					<h5>Explore Course ({ClassesData.length})</h5>
					{isLoading ? (
						<div style={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<RotatingLines
								strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="100"
								visible={true}
							/>
						</div>
					) : (
						<div className="LearningClassContainer">
							{ClassesData.map((item, index) => {
								if (item.classType !== "live")
									return (
										<div
											key={index}
											className="LearningClassBox"
											onClick={() => navigate("/learning/CourseDetails/" + item._id)}>
											<div className="LearningClassBoxImageCont">
												<img src={item.coverpic} alt="" />
											</div>
											<div className="LearningClassBoxTextCont">
												<h6>{item.courseTitle}</h6>
												<p>
													{item.courseDescription.slice(0, 100)}
													{item.courseDescription.length > 100 && ". . . ."}
												</p>
												<span>
													{item.createdAt.split("T")[0] +
														" at " +
														item.createdAt.split("T")[1].split(".")[0]}
												</span>
											</div>
										</div>
									);
							})}
						</div>
					)}
					<div className="AlbumPagination">
						<button disabled={offsetTopAlbum === 0 && true} onClick={() => setOffsetTopAlbum(offsetTopAlbum - 10)}>
							{"<"} Prev
						</button>
						<button
							disabled={ClassesData?.length < 10 && true}
							onClick={() => setOffsetTopAlbum(offsetTopAlbum + 10)}>
							Next {">"}
						</button>
					</div>
				</div>

				<div className="LearningClassMainContainer">
					<h5>Explore Live Classes ({ClassesData.length})</h5>
					{isLoading ? (
						<div style={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<RotatingLines
								strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="100"
								visible={true}
							/>
						</div>
					) : (
						<div className="LearningClassContainer">
							{ClassesData.map((item, index) => {
								if (item.classType === "live")
									return (
										<div
											key={index}
											className="LearningClassBox"
											onClick={() => navigate("/learning/CourseDetails/" + item._id)}>
											<div className="LearningClassBoxImageCont">
												<img src={item.coverpic} alt="" />
											</div>
											<div className="LearningClassBoxTextCont">
												<h6>{item.courseTitle}</h6>
												<p>
													{item.courseDescription.slice(0, 100)}
													{item.courseDescription.length > 100 && ". . . ."}
												</p>
												<span>
													{item.createdAt.split("T")[0] +
														" at " +
														item.createdAt.split("T")[1].split(".")[0]}
												</span>
											</div>
										</div>
									);
							})}
						</div>
					)}
					<div className="AlbumPagination">
						<button disabled={offsetTopAlbum === 0 && true} onClick={() => setOffsetTopAlbum(offsetTopAlbum - 10)}>
							{"<"} Prev
						</button>
						<button
							disabled={ClassesData?.length < 10 && true}
							onClick={() => setOffsetTopAlbum(offsetTopAlbum + 10)}>
							Next {">"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Learning;
