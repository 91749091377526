import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAlbumsQuery, useGetLatestAlbumsQuery, useGetTopAlbumsQuery } from "../../Redux/api/albumApi";
import { setAlbumChange, setSearch } from "../../Redux/slices/TempSlice";
import useUserValidate from "../../components/Hooks/useUserValidate";
import searchIcon from "../../images/Vector (2).png";
import Header from "../header.component";
import AddSong from "./AddSong";
import AlbumPopup from "./AlbumPopup";
import CreateAlbum from "./CreateAlbum";
import Player from "./Player";

function Album() {
	const albumId = useParams().id;
	useUserValidate();
	const dispatch = useDispatch();

	const [showCreateAlbum, setShowCreateAlbum] = useState(false);
	const [showAlbumPopup, setShowAlbumPopup] = useState(albumId === undefined ? false : true);
	const [showAddSongPopup, setShowAddSongPopup] = useState(false);
	const [createdAlbumId, setCreatedAlbumId] = useState(albumId === undefined ? "" : albumId);
	const [reloadSongComponent, setReloadSongComponent] = useState(0);
	const [allSongs, setAllSongs] = useState([]);
	const [offsetSong, setOffsetSong] = useState(0);
	const [offsetTopAlbum, setOffsetTopAlbum] = useState(0);
	const [offsetLatestAlbum, setOffsetLatestAlbum] = useState(0);
	const [offsetAlbum, setOffsetAlbum] = useState(0);

	useEffect(() => {
		dispatch(setAlbumChange(true));
		setCreatedAlbumId(albumId);
	}, [albumId]);

	const { data = [], isLoading } = useGetAlbumsQuery({
		id: localStorage.getItem("user_id") || sessionStorage.getItem("user_id"),
		offsetAlbum,
	});
	const { data: topAlbum = [], isLoading: isTopAlbumLoading } = useGetTopAlbumsQuery(offsetTopAlbum);
	const { data: latestAlbum = [], isLoading: isLatestAlbumLoading } = useGetLatestAlbumsQuery(offsetLatestAlbum);
	const { currentSelectedSong } = useSelector((state) => state.albumSlice);

	// const slider = document.querySelectorAll(".AlbumItems");
	// let isDown = false;
	// let startX;
	// let scrollLeft;

	// slider?.forEach((slider) => {
	// 	slider?.addEventListener("mousedown", (e) => {
	// 		isDown = true;
	// 		startX = e.pageX - slider.offsetLeft;
	// 		scrollLeft = slider.scrollLeft;
	// 	});
	// 	slider?.addEventListener("mouseleave", () => {
	// 		isDown = false;
	// 	});
	// 	slider?.addEventListener("mouseup", () => {
	// 		isDown = false;
	// 	});
	// 	slider?.addEventListener("mousemove", (e) => {
	// 		if (!isDown) return;
	// 		e.preventDefault();
	// 		const x = e.pageX - slider.offsetLeft;
	// 		const walk = (x - startX) * 2; //scroll-fast
	// 		slider.scrollLeft = scrollLeft - walk;
	// 	});
	// });

	return (
		<div className="UserMainContainer">
			<Header />

			{showCreateAlbum && (
				<CreateAlbum
					setShowAlbumPopup={setShowAlbumPopup}
					setShowCreateAlbum={setShowCreateAlbum}
					setCreatedAlbumId={setCreatedAlbumId}
				/>
			)}
			{showAlbumPopup && (
				<AlbumPopup
					setShowAlbumPopup={setShowAlbumPopup}
					createdAlbumId={createdAlbumId}
					setShowAddSongPopup={setShowAddSongPopup}
					reloadSongComponent={reloadSongComponent}
					allSongs={allSongs}
					setAllSongs={setAllSongs}
					offsetSong={offsetSong}
					setOffsetSong={setOffsetSong}
				/>
			)}

			{showAddSongPopup && (
				<AddSong
					setShowAddSongPopup={setShowAddSongPopup}
					setShowAlbumPopup={setShowAlbumPopup}
					createdAlbumId={createdAlbumId}
				/>
			)}

			<div className="albumBody">
				<div className="FirstContainer">
					<h1>Album</h1>
					<div>
						<img src={searchIcon} alt="" onClick={() => dispatch(setSearch({ search: true, type: "albumTitle" }))} />
						<button onClick={() => setShowCreateAlbum(true)}>+ Create</button>
					</div>
				</div>

				<div className="SecondContainer">
					<p>Top Album</p>
					{isTopAlbumLoading ? (
						<div
							style={{
								with: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<ColorRing
								visible={true}
								height="100"
								width="100"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : (
						<div className="AlbumItems">
							<AliceCarousel
								mouseTracking
								disableButtonsControls
								disableDotsControls
								responsive={{
									0: {
										items: 5,
									},
									1100: {
										items: 6,
									},
									1300: {
										items: 7,
									},
									1500: {
										items: 8,
									},
									1670: {
										items: 9,
									},
								}}>
								{topAlbum?.data?.map((album, index) => (
									<div
										key={index}
										title={album.title}
										className="albumItem"
										onDragStart={(e) => e.preventDefault()}
										onClick={() => {
											setCreatedAlbumId(album._id);
											setShowAlbumPopup(true);
											setReloadSongComponent(Math.random());
											setAllSongs([]);
											setOffsetSong(0);
										}}>
										<img src={album.cover_photo} alt="Album Photo" />
									</div>
								))}
							</AliceCarousel>
						</div>
					)}
					<div className="AlbumPagination">
						<button disabled={offsetTopAlbum === 0 && true} onClick={() => setOffsetTopAlbum(offsetTopAlbum - 10)}>
							{"<"} Prev
						</button>
						<button
							disabled={topAlbum?.data?.length < 10 && true}
							onClick={() => setOffsetTopAlbum(offsetTopAlbum + 10)}>
							Next {">"}
						</button>
					</div>
				</div>

				<div className="SecondContainer">
					<p>Latest Album</p>
					{isLatestAlbumLoading ? (
						<div
							style={{
								with: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<ColorRing
								visible={true}
								height="100"
								width="100"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : (
						<div className="AlbumItems">
							<AliceCarousel
								mouseTracking
								disableButtonsControls
								disableDotsControls
								responsive={{
									0: {
										items: 5,
									},
									1100: {
										items: 6,
									},
									1300: {
										items: 7,
									},
									1500: {
										items: 8,
									},
									1670: {
										items: 9,
									},
								}}>
								{latestAlbum?.data?.map((album, index) => (
									<div
										key={index}
										title={album.title}
										className="albumItem"
										onDragStart={(e) => e.preventDefault()}
										onClick={() => {
											setCreatedAlbumId(album._id);
											setShowAlbumPopup(true);
											setReloadSongComponent(Math.random());
											setAllSongs([]);
											setOffsetSong(0);
										}}>
										<img src={album.cover_photo} alt="Album Photo" />
									</div>
								))}
							</AliceCarousel>
						</div>
					)}
					<div className="AlbumPagination">
						<button
							disabled={offsetLatestAlbum === 0 && true}
							onClick={() => setOffsetLatestAlbum(offsetLatestAlbum - 10)}>
							{"<"} Prev
						</button>
						<button
							disabled={latestAlbum?.data?.length < 10 && true}
							onClick={() => setOffsetLatestAlbum(offsetLatestAlbum + 10)}>
							Next {">"}
						</button>
					</div>
				</div>

				<div className="SecondContainer">
					<p>My Album</p>
					{isLoading ? (
						<div
							style={{
								with: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<ColorRing
								visible={true}
								height="100"
								width="100"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : (
						<div className="AlbumItems">
							<AliceCarousel
								mouseTracking
								disableButtonsControls
								disableDotsControls
								responsive={{
									0: {
										items: 5,
									},
									1100: {
										items: 6,
									},
									1300: {
										items: 7,
									},
									1500: {
										items: 8,
									},
									1670: {
										items: 9,
									},
								}}>
								{data?.data?.map((album, index) => (
									<div
										key={index}
										title={album.title}
										className="albumItem"
										onDragStart={(e) => e.preventDefault()}
										onClick={() => {
											setCreatedAlbumId(album._id);
											setShowAlbumPopup(true);
											setReloadSongComponent(Math.random());
											setAllSongs([]);
											setOffsetSong(0);
										}}>
										<img src={album.cover_photo} alt="Album Photo" />
									</div>
								))}
							</AliceCarousel>
							{data?.data?.length === 0 && <p>No Album</p>}
						</div>
					)}
					<div className="AlbumPagination">
						<button disabled={offsetAlbum === 0 && true} onClick={() => setOffsetAlbum(offsetAlbum - 10)}>
							{"<"} Prev
						</button>
						<button disabled={data?.data?.length < 10 && true} onClick={() => setOffsetAlbum(offsetAlbum + 10)}>
							Next {">"}
						</button>
					</div>
				</div>

				<Player />
			</div>
		</div>
	);
}
export default Album;
