import { useNavigate } from "react-router-dom";

function HeaderProfessional({ show, setShow, show2, setShow2 }) {
	const navigate = useNavigate();
	if (!show) {
		return null;
	} else
		return (
			<>
				<div className="responsive-popup2">
					<button className="close-popup " onClick={() => setShow(false)}>
						X
					</button>

					<div className="responsive-popup-box2 ">
						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								setShow2(true);
							}}>
							<p>Notification</p>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-wallet");
							}}>
							<p>Wallet</p>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-account");
							}}>
							<div className="display-flex">
								<p>My Profile</p>
							</div>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-MyClasses");
							}}>
							<div className="display-flex">
								<p>My Class</p>
							</div>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-ActiveHours");
							}}>
							<div className="display-flex">
								<p>Active Hours </p>
							</div>
						</button>

						<button className="responsive-threeDotBox">
							<p>Chat</p>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-support");
							}}>
							<div className="display-flex">
								<p>Support & Help</p>
							</div>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-privacy");
							}}>
							<div className="display-flex">
								<p>Privacy Policy</p>
							</div>
						</button>

						<button
							className="responsive-threeDotBox"
							onClick={() => {
								setShow(false);
								navigate("/professional-aboutUs");
							}}>
							<div className="display-flex">
								<p>About Us</p>
							</div>
						</button>
					</div>
				</div>
			</>
		);
}
export default HeaderProfessional;
