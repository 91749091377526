import { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSearch } from "../Redux/slices/TempSlice";
import axios from "../components/Hooks/axios";
import styles from "../css/SearchBar.module.scss";
import { RatingBtn } from "../components/RatingBtn";

function SearchBar() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { search, searchType } = useSelector((state) => state.temp);
	const [searchText, setSearchText] = useState("");
	const [searchResult, setSearchResult] = useState([]);

	useEffect(() => {
		setSearchResult([]);
	}, [searchType]);

	const handelSearch = () => {
		let data = new FormData();
		data.append(searchType, searchText);
		axios
			.post(`/search`, data)
			.then(({ data }) => {
				if (data.status) {
					if (!data.data) return toast.warn("No Search Data Found");
					setSearchResult(data.data);
				} else {
					console.log("Incorrect");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const audioRef = useRef(null);
	const handleBlur = () => {
		audioRef.current.pause();
	};

	const videoRef = useRef(null);
	const handleVideoBlur = () => {
		console.log("first");
		videoRef.current.pause();
	};

	const Post = (data, index) => {
		let PostRating = data.rating;
		if (data.media.length === 0) {
			return (
				<div className={styles.postContainer} key={index}>
					<div className={styles.onlyCaption}>{data.caption}</div>
					<div className={styles.ProfileSection}>
						<div className={styles.left}>
							<img src={data?.created_by?.pic} alt="" />
						</div>
						<div className={styles.right}>
							<p
								onClick={() => {
									console.log("first");
									navigate(`/profile/${data?.created_by?._id}`);
									dispatch(setSearch({ search: false, type: "" }));
								}}>
								{data?.created_by?.full_name}
							</p>
							<div className="PostProfileStarContainer">
								{Array.from({ length: 5 }, (_, index) => {
									let starHalf = index + 0.5;
									return PostRating >= index + 1 ? (
										<FaStar key={index} color={"#FFA800"} />
									) : PostRating >= starHalf ? (
										<FaStarHalfAlt key={index} color={"#FFA800"} />
									) : (
										<FaRegStar key={index} color={"#FFA800"} />
									);
								})}
								<p> &nbsp;{PostRating} Star</p>
							</div>
						</div>
						<div className={styles.rating}>
							<RatingBtn
								postUserId={data?.created_by?._id}
								postId={data._id}
								totalRating={data.rating_data[0]?.totalrating}
								// setPostRating={setPostRating}
								is_rated={data.is_rated}
								rated_count={data.rated_count}
								// setProfileRating={setProfileRating}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			let type = data.media[0].type;
			let dataUrl = data.media[0].file;
			return (
				<div className={styles.postContainer} key={index}>
					<div className={styles.content}>
						{type === "image" && <img src={dataUrl} />}
						{type === "video" && (
							<video
								className="video"
								width="520"
								height="220"
								onBlur={handleVideoBlur}
								ref={videoRef}
								controls
								controlsList="nodownload">
								<source src={dataUrl} type="video/mp4" />
								<source src={dataUrl} type="video/webm" />
								<source src={dataUrl} type="video/wmv" />
							</video>
						)}
						{type === "audio" && (
							<audio
								controls
								autoPlay={false}
								ref={audioRef}
								onBlur={handleBlur}
								src={dataUrl}
								controlsList="nodownload"></audio>
						)}
					</div>
					<div className={styles.Caption}>{data.caption}</div>
					<div className={styles.ProfileSection}>
						<div className={styles.left}>
							<img src={data?.created_by?.pic} alt="" />
						</div>
						<div className={styles.right}>
							<p
								onClick={() => {
									console.log("first");
									navigate(`/profile/${data?.created_by?._id}`);
									dispatch(setSearch({ search: false, type: "" }));
								}}>
								{data?.created_by?.full_name}
							</p>
							<div className="PostProfileStarContainer">
								{Array.from({ length: 5 }, (_, index) => {
									let starHalf = index + 0.5;
									return PostRating >= index + 1 ? (
										<FaStar key={index} color={"#FFA800"} />
									) : PostRating >= starHalf ? (
										<FaStarHalfAlt key={index} color={"#FFA800"} />
									) : (
										<FaRegStar key={index} color={"#FFA800"} />
									);
								})}
								<p> &nbsp;{PostRating} Star</p>
							</div>
						</div>
						<div className={styles.rating}>
							<RatingBtn
								postUserId={data?.created_by?._id}
								postId={data._id}
								totalRating={data.rating_data[0]?.totalrating}
								// setPostRating={setPostRating}
								is_rated={data.is_rated}
								rated_count={data.rated_count}
								// setProfileRating={setProfileRating}
							/>
						</div>
					</div>
				</div>
			);
		}
	};

	const Album = (data, index) => {
		return (
			<div
				key={index}
				className={styles.albumContainer}
				onClick={() => {
					navigate(`/album/${data._id}`);
					dispatch(setSearch({ search: false, type: "" }));
				}}>
				<img src={data.cover_photo} alt="Album Image" />
				<div className={styles.albumDetails}>
					<h5>{data.title}</h5>
					<p>{data.description}</p>
				</div>
			</div>
		);
	};

	const Class = (data, index) => {
		return (
			<div
				key={index}
				className={styles.albumContainer}
				onClick={() => {
					navigate(`/learning/CourseDetails/${data._id}`);
					dispatch(setSearch({ search: false, type: "" }));
				}}>
				<img src={data.coverpic} alt="Class Image" />
				<div className={styles.albumDetails}>
					<h5>{data.courseTitle}</h5>
					<p>{data.courseDescription}</p>

					<p className={styles.author}>
						{" "}
						created by <span>{data?.created_by?.full_name}</span>
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.UserSearchBar}>
			<button className={styles.closePopup} onClick={() => dispatch(setSearch({ search: false, type: "" }))}>
				<RxCross2 />
			</button>
			<div className={styles.UserSearchBarPopupBox}>
				<h1>SEARCH</h1>
				<div className={styles.filterBox}>
					<button
						onClick={() => dispatch(setSearch({ search: true, type: "postCaption" }))}
						style={{
							background: searchType === "postCaption" ? "#471cf1" : "",
							color: searchType === "postCaption" ? "#fff" : "",
						}}>
						Caption
					</button>
					<button
						onClick={() => dispatch(setSearch({ search: true, type: "albumTitle" }))}
						style={{
							background: searchType === "albumTitle" ? "#471cf1" : "",
							color: searchType === "albumTitle" ? "#fff" : "",
						}}>
						Album
					</button>
					<button
						onClick={() => dispatch(setSearch({ search: true, type: "courseTitle" }))}
						style={{
							background: searchType === "courseTitle" ? "#471cf1" : "",
							color: searchType === "courseTitle" ? "#fff" : "",
						}}>
						Class
					</button>
					<button
						onClick={() => dispatch(setSearch({ search: true, type: "username" }))}
						style={{
							background: searchType === "username" ? "#471cf1" : "",
							color: searchType === "username" ? "#fff" : "",
						}}>
						Name
					</button>
				</div>
				<div className={styles.searchBox}>
					<input
						type="search"
						className={styles.search}
						placeholder="Search"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") handelSearch();
						}}
					/>
					<div className={styles.iconBox} onClick={handelSearch}>
						<BsSearch className={styles.icon} />
					</div>
				</div>
				<div className={styles.result}>
					{searchType === "postCaption" && searchResult.map(Post)}
					{searchType === "albumTitle" && searchResult.map(Album)}
					{searchType === "courseTitle" && searchResult.map(Class)}
				</div>
			</div>
		</div>
	);
}
export default SearchBar;
