import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useValidateWithoutLogin = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
			if (localStorage.getItem("user_type") == "professional" || sessionStorage.getItem("user_type") == "professional")
				return navigate("/Professional-home");
			else if (localStorage.getItem("user_type") == "user" || sessionStorage.getItem("user_type") == "user")
				return navigate("/dashboard");
		}
	}, []);
};

export default useValidateWithoutLogin;
