import React, { useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgTime } from "react-icons/cg";
import { FiBook } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import useProfessionalValidate from "../../components/Hooks/useProfessionalValidate";
import styles from "../../css/LiveClass.module.scss";
import Header from "../Professional_header";
import AddLecture from "./AddLecture";

const LiveClassInfo = () => {
	useProfessionalValidate();
	const navigate = useNavigate();
	const [threePopupShow, setThreePopupShow] = useState(false);
	const [activePopup, setActivePopup] = useState(null);
	const [addLectureShow, setAddLectureShow] = useState(false);
	const { state } = useLocation();
	const [classData, setClassData] = useState([]);
	const [LectureData, setLectureData] = useState([]);
	const [reload, setReload] = useState(0);
	const [activeLectureIndex, setActiveLectureIndex] = useState(0);

	useEffect(() => {
		if (state === "") return;
		axios
			.get(`/class/getOneClass/${state}`)
			.then(({ data }) => {
				setClassData(data?.data);
				setLectureData(data?.data?.media);
			})
			.catch((e) => console.log(e));
	}, [state, reload]);

	const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const ingestEndpoint = LectureData[activeLectureIndex]?.ivsChannel?.channel?.ingestEndpoint;
	const streamKey = LectureData[activeLectureIndex]?.ivsChannel?.streamKey?.value;
	const PlayBackUrl = LectureData[activeLectureIndex]?.ivsChannel?.channel?.playbackUrl;
	const baseUrl = window.location.origin;

	const handelCopy = async() => {
		await navigator?.clipboard?.writeText(`${baseUrl}/watchLiveClass?PlayBackUrl=${PlayBackUrl}`)
			.then(() => {
				setThreePopupShow(false);
				toast.success("URL copied to clipboard");
			})
			.catch((err) => {
				console.error("Failed to copy URL: ", err);
			});
	};

	return (
		<div className="professionalMainContainer">
			<Header />
			{addLectureShow && <AddLecture setShow={setAddLectureShow} classId={classData._id} setReload={setReload} />}

			<div className={`ProfessionalBody  ${styles.LiveClassInfo}`}>
				<div className={styles.LeftContainer}>
					<div className={styles.TopBtnContainer}>
						<button onClick={() => setAddLectureShow(true)}>+ Add lecture</button>
					</div>

					<div className={styles.VideoContainer}>
						<img src={classData?.coverpic} alt="" />
						<div className={styles.videoBottom}>
							<div className={styles.Left}>
								<h5>{classData?.courseTitle}</h5>
								<h6>
									By <span>{classData?.created_by?.full_name}</span>
								</h6>
							</div>
							<div className={styles.Right}>
								<MdModeEdit className={styles.Icon} />
								<p>
									<CgTime />
									<span>{classData?.createdAt?.split(".")[0].split("T")[0]}</span>
								</p>
							</div>
						</div>
					</div>

					<div className={styles.LectureContainer}>
						{LectureData.length === 0 ? (
							<h1>No Lectures</h1>
						) : (
							LectureData.map((data, index) => (
								<div className={styles.LectureBox} key={index} onClick={() => setActiveLectureIndex(index)}>
									<div className={styles.Left}>{index + 1}</div>
									<div className={styles.Right}>
										<div className={styles.Top}>
											<div className={styles.TopLeft}>
												<h1>{data?.lectureTitle}</h1>
												{/* <h6 style={{ color: index === 0 ? "red" : "" }}>
												<CgTime />
												<span>0 min Left</span>
											</h6> */}
											</div>
											<div className={styles.TopRight}>
												<p>{data?.lectureDate?.split(".")[0]?.split("T")[0]?.split("-")[2]}</p>
												<p>{monthNames[new Date(data?.lectureDate).getMonth()]}</p>
												<p>{data?.lectureDate?.split(".")[0]?.split("T")[0]?.split("-")[0]}</p>
												<h6>{days[new Date(data?.lectureDate).getDay()]}</h6>
												<BsThreeDotsVertical
													className={styles.threeDot}
													onClick={() => {
														setActivePopup(index);
														setThreePopupShow(!threePopupShow);
													}}
												/>
											</div>
										</div>
										<div className={styles.Bottom}>
											<div className={styles.BottomLeft}>
												<div className={styles.p}>{data?.lectureDesc}</div>
												<div className={styles.BBB}>
													<p>Time Duration</p>
													<p>{data?.lectureDuration} Min</p>
													{/* <p>04 : 04</p>
												<span>To :</span>
												<p>05 : 00</p> */}
												</div>
											</div>
											<div className={styles.BottomRight}>
												<button className={styles.null}>Start Class</button>
											</div>
										</div>
									</div>

									{threePopupShow && activePopup === index && (
										<div className={styles.ThreeBoxContainer}>
											<button
												onClick={() => {
													setThreePopupShow(false);
												}}>
												Delete lecture
											</button>
											<button
												onClick={() => {
													setThreePopupShow(false);
												}}>
												Share
											</button>
											<button onClick={handelCopy}>Copy link</button>
										</div>
									)}
								</div>
							))
						)}
					</div>
				</div>

				<div className={styles.RightContainer}>
					{LectureData.length > 0 && (
						<div className={styles.Top}>
							<div className={styles.Video} style={{ display: "none" }}>
								{/* <video src=""></video> */}
								<img src="https://picsum.photos/200/300" alt="" />
							</div>

							<div className={styles.TextContainer}>
								<h1>
									{LectureData[activeLectureIndex]?.lectureTitle}
									{/* <span>
									<CgTime /> 0 min Left
								</span> */}
								</h1>

								<p>{LectureData[activeLectureIndex]?.lectureDesc}</p>

								<div className={styles.BBB}>
									<p>Time Duration:</p>
									<p>{LectureData[activeLectureIndex]?.lectureDuration}</p>
									{/* <p>04 : 04</p>
								<span>To :</span>
								<p>05 : 00</p> */}
								</div>

								<div className={styles.date}>
									<p>
										{
											LectureData[activeLectureIndex]?.lectureDate
												?.split(".")[0]
												?.split("T")[0]
												?.split("-")[2]
										}
									</p>
									<p>{monthNames[new Date(LectureData[activeLectureIndex]?.lectureDate).getMonth()]}</p>
									<p>
										{
											LectureData[activeLectureIndex]?.lectureDate
												?.split(".")[0]
												?.split("T")[0]
												?.split("-")[0]
										}
									</p>
									<span>{days[new Date(LectureData[activeLectureIndex]?.lectureDate).getDay()]}</span>
								</div>

								<div className={styles.ButtonContainer}>
									<button>Cancel lecture</button>

									<button
										onClick={() =>
											navigate(
												`/Professional-LiveSection?ingestEndpoint=${ingestEndpoint}&streamKey=${streamKey}`
											)
										}>
										Start class
									</button>
								</div>
							</div>
						</div>
					)}

					<div className={styles.Bottom}>
						<h3>Classes Resources</h3>
						<div className={styles.Container}>
							<div className={styles.BoxContainer}>
								<div className={styles.Box}>
									<FiBook size={28} />
								</div>
								<h6>Notes</h6>
							</div>
							<div className={styles.BoxContainer}>
								<div className={styles.Box}>
									<AiFillInfoCircle size={28} />
								</div>
								<h6>Info</h6>
							</div>
							<div className={styles.BoxContainer} onClick={() => navigate("/Professional-support")}>
								<div className={`${styles.Box} ${styles.Support}`}>
									<BiSupport size={28} />
								</div>
								<h6>Support</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LiveClassInfo;
