import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../components/Hooks/axios";
import useProfessionalValidate from "../../components/Hooks/useProfessionalValidate";
import styles from "../../css/LiveClass.module.scss";
import searchIcon from "../../images/Vector (2).png";
import Header from "../Professional_header";
import CreateClass from "./CreateClass";
import EditClass from "./EditClass";

const LiveClass = () => {
	useProfessionalValidate();
	const navigate = useNavigate();
	const { userId } = useSelector((s) => s.auth);
	const [popupShow, setPopupShow] = useState(false);
	const [activeClassId, setActiveClassId] = useState("");
	const [editShow, setEditShow] = useState(false);
	const [createClassShow, setCreateClassShow] = useState(false);
	const [allClasses, setAllClasses] = useState([]);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		if (userId === "") return;
		axios
			.get(`/class/getclassByUserid/${userId}`)
			.then(({ data }) => {
				setAllClasses(data.data);
			})
			.catch((e) => console.log(e));
	}, [userId, reload]);

	return (
		<div className="professionalMainContainer">
			<Header />

			{createClassShow && <CreateClass setShow={setCreateClassShow} setReload={setReload} />}
			{editShow && <EditClass setShow={setEditShow} />}

			<div className={`ProfessionalBody  ${styles.LiveClass}`}>
				<div className={styles.Heading}>
					<div className={styles.Left}>
						<h1>
							<BiChevronLeft className={styles.icon} onClick={() => navigate(-1)} /> Live Classes
						</h1>
						<p>Schedule Class</p>
					</div>
					<div className={styles.Right}>
						<img src={searchIcon} className="album-search" alt="Search" />
						<button onClick={() => setCreateClassShow(true)}>+ Create Live Class</button>
					</div>
				</div>

				<div className={styles.Body}>
					{allClasses.map((data, index) => {
						if (data.classType === "live")
							return (
								<div
									key={index}
									className={styles.Box}
									onClick={() => navigate(`/Professional-LiveClassInfo`, { state: data._id })}>
									<div className={styles.Top}>
										<div className={styles.overlay} />
										<img src={data.coverpic} alt="" />
										<BsThreeDotsVertical
											className={styles.threeDot}
											onClick={(e) => {
												e.stopPropagation();
												setPopupShow(!popupShow);
												setActiveClassId(index);
											}}
										/>
										{popupShow && activeClassId === index && (
											<div className={styles.OptionBox} onClick={(e) => e.stopPropagation()}>
												<p
													onClick={() => {
														setPopupShow(!popupShow);
														setEditShow(true);
													}}>
													Edit Class
												</p>
												<p
													onClick={() => {
														console.log("Share");
														setPopupShow(!popupShow);
													}}>
													Share
												</p>
											</div>
										)}
									</div>
									<div className={styles.Bottom}>
										<h2>{data.courseTitle}</h2>
										<div>
											<p>{data.courseDescription}</p>
											<h3>
												{data.media.length}
												<span>Lectures</span>
											</h3>
										</div>
									</div>
								</div>
							);
					})}
				</div>
			</div>
		</div>
	);
};

export default LiveClass;
