import { Box, Button, DialogActions, DialogContent, Slider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

export const CropEasy = ({ photoURL, setOpenCrop, setPhotoURL, setFile }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const cropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const cropImage = async () => {
		try {
			const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
			setPhotoURL(url);
			setFile(file);
			setOpenCrop(false);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="main-crop-container">
			<DialogContent
				dividers
				className="cropActionBox1"
				sx={{
					background: "#333",
					position: "relative",
					height: 500,
					width: "100%",
					minWidth: { sm: 500 },
					overflow: "hidden",
				}}>
				<Cropper
					image={photoURL}
					crop={crop}
					zoom={zoom}
					rotation={rotation}
					aspect={1}
					onZoomChange={setZoom}
					onRotationChange={setRotation}
					onCropChange={setCrop}
					onCropComplete={cropComplete}
				/>
			</DialogContent>
			<DialogActions className="cropActionBox2" sx={{ display: "block", flexDirection: "column", mx: 3, my: 2 }}>
				<Box sx={{ width: "100%", mb: 1 }}>
					<Box>
						<Typography style={{ color: "#000" }}>Zoom: {`${Math.round(zoom * 100)}%`}</Typography>
						<Slider
							valueLabelDisplay="auto"
							valueLabelFormat={zoomPercent}
							min={1}
							max={3}
							step={0.1}
							value={zoom}
							onChange={(e, zoom) => setZoom(zoom)}
						/>
					</Box>
					<Box>
						<Typography style={{ color: "#000" }}>Rotation: {rotation}</Typography>
						<Slider
							valueLabelDisplay="auto"
							min={0}
							max={360}
							value={rotation}
							onChange={(e, rotation) => setRotation(rotation)}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						flexWrap: "wrap",
					}}>
					<Button variant="outlined" className="btn-cancle" startIcon={"X"} onClick={() => setOpenCrop(false)}>
						Cancel
					</Button>
					<Button className="btn-crop" variant="contained" startIcon={"✂"} onClick={cropImage}>
						Done
					</Button>
				</Box>
			</DialogActions>
		</div>
	);
};

const zoomPercent = (value) => {
	return `${Math.round(value * 100)}%`;
};
