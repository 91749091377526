import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import threeDots from "../images/3dot.png";
// import logo from "../images/MusicBookLogo.png";
import logo2 from "../images/logoM.png";
import HeaderProfessional from "./hederPopup.component";
import Notification from "./ProfessionalNotification";

function Header() {
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);

	return (
		<>
			<div className="bg-black text-white professional-header">
				<HeaderProfessional show={show} setShow={setShow} show2={show2} setShow2={setShow2} />
				{show2 && <Notification setShow2={setShow2} />}
				<div
					className="display-flex"
					onClick={() => window.open("/professional-home", "_self")}
					style={{ cursor: "pointer" }}>
					<img
						src={logo2}
						alt=""
						style={{
							borderRadius: "50%",
							height: "50px",
							width: "50px",
							boxShadow: "0 0 10px 3px rgba(255,255,255,.3)",
						}}
					/>
					<div>
						<h5>Music book</h5>
						<p>Live every beat of your life</p>
					</div>
				</div>

				<img
					className="three-dot-img"
					src={threeDots}
					onClick={() => setShow(true)}
					alt="Logo"
					style={{ cursor: "pointer" }}
				/>
			</div>
		</>
	);
}
export default Header;
