import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderProfessional from "../Professional/Professional_header";
import Header from "../User/header.component";
import styles from "../css/Support.module.scss";
import axios from "./Hooks/axios";
import SupportChartPopup from "./SupportChartPopup";
import SupportEmailPopup from "./SupportEmailPopup";

const Support = () => {
	// useUserValidate();
	const { token, userType,userId } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const [showChat, setShowChat] = useState(false);
	const [ticketData, setTicketData] = useState({});
	const [showEmail, setShowEmail] = useState(false);
	const [ticketsData, setTicketsData] = useState([]);
	const [raisePopup, setRaisePopup] = useState(false);
	const [raiseTitle, setRaiseTitle] = useState("");
	const [reload, setReload] = useState(0);

	useEffect(() => {
		setShowEmail(token ? false : true);
	}, [token]);

	useEffect(() => {
		if (userId === "") return;
			axios
				.get(`/ticket/get/${userId}`)
				.then(({ data }) => {
					setTicketsData(data.data);
				})
				.catch((e) => console.log(e));
	}, [reload, userId]);

	const handelRaseTicket = () => {
		axios
			.post("/ticket/create", {
				ticketTitle: raiseTitle,
			})
			.then(({ data }) => {
				toast.success(data.message);
				setRaisePopup(false);
				setReload(Math.random());
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	return (
		<div className={styles.SupportMainContainer}>
			{token && userType === "user" ? <Header /> : <HeaderProfessional />}
			<div className={styles.SupportContainer}>
				<div className={styles.ContainerLeft}>
					<div className={styles.heading}>
						<h5>
							<span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
								◄
							</span>
							Support
						</h5>
						<button
							onClick={() => {
								setShowChat(false);
								setShowEmail(!showEmail);
							}}>
							{" "}
							Email Us
						</button>
					</div>
					{token && (
						<>
							<p className={styles.Recent}>Recent</p>
							<div className={styles.body}>
								{[...ticketsData].reverse().map((data, index) => {
									const date = new Date(data.createdAt);
									return (
										<div
											key={index}
											className={styles.box}
											onClick={() => {
												setTicketData(data);
												setShowChat(true);
												setShowEmail(false);
											}}>
											<div className={styles.left}>
												<p>Ticket No #{data.ticketNumber}</p>
												<h2>{data.ticketTitle}</h2>
												<p>
													{date.getDate()} {month[date.getMonth()]} {date.getFullYear()}{" "}
												</p>
											</div>
											<div className={`${styles.right} ${!data.ticketStaus && styles.close} `}>
												{data.ticketStaus ? "Active" : "Close"}
											</div>
										</div>
									);
								})}
							</div>
							<div className={styles.button} onClick={() => setRaisePopup(true)}>
								Raise Ticket
							</div>
						</>
					)}
				</div>
				{raisePopup && (
					<div className={styles.PopupBackground} onClick={() => setRaisePopup(false)}>
						<motion.div
							initial={{ y: "400px" }}
							animate={{ y: "0px" }}
							className={styles.Container}
							onClick={(e) => e.stopPropagation()}>
							<input type="text" value={raiseTitle} onChange={(e) => setRaiseTitle(e.target.value)} placeholder="Ticket Title" />
							<button onClick={handelRaseTicket}>Submit</button>
						</motion.div>
					</div>
				)}
				{showChat && <SupportChartPopup setShowChat={setShowChat} ticketData={ticketData} />}
				{showEmail && <SupportEmailPopup setShowEmail={setShowEmail} />}
			</div>
		</div>
	);
};

export default Support;
