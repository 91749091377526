import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import styles from "../../css/LiveClassPopup.module.scss";

const EditClass = ({ setShow }) => {
	const [image, setImage] = useState(null);
	const inputRef = useRef(null);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const handelUpdate = () => {
		console.log("Upload");
		setShow(false);
		toast.success("Your class successfully updated");
	};

	return (
		<div onClick={() => setShow(false)} className={styles.PopupBackground}>
			<motion.div
				initial={{ y: "-500px" }}
				animate={{ y: "0px" }}
				className={styles.EditClassPopup}
				onClick={(e) => e.stopPropagation()}>
				<div className={styles.heading}>
					<h1>Edit class</h1>
					<BiChevronLeft className={styles.icon} onClick={() => setShow(false)} />
				</div>

				<div className={styles.coverContainer} onClick={() => inputRef.current.click()}>
					<div className={styles.ImageCont}>
						{image && <img src={URL.createObjectURL(image)} alt="" />}
						<p>
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM11.14 8.86L8.14 12.73L6 10.14L3 14H15L11.14 8.86Z"
									fill="white"
								/>
							</svg>
							change cover image
						</p>
						<input type="file" ref={inputRef} onChange={(e) => setImage(e.target.files[0])} />
					</div>
				</div>

				<div className={styles.groupContainer}>
					<label>Title of class</label>
					<div className={styles.groupBox}>
						<input type="text" placeholder="Class Title" value={title} onChange={(e) => setTitle(e.target.value)} />
						<MdEdit size={20} />
					</div>
				</div>

				<div className={styles.groupContainer}>
					<label>Title of class</label>
					<div className={styles.groupBox}>
						<textarea
							placeholder="Class Description"
							defaultValue={description}
							onChange={(e) => setDescription(e.target.value)}></textarea>
						<MdEdit size={20} />
					</div>
				</div>

				<button onClick={handelUpdate}>Update</button>
			</motion.div>
		</div>
	);
};

export default EditClass;
