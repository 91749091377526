import React, { useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
// import useProfessionalValidate from "../../components/Hooks/useProfessionalValidate";
import styles from "../../css/LiveClass.module.scss";
import Header from "../Professional_header";
import EndLivePopup from "./EndLivePopup";
import JoinersPopup from "./JoinersPopup";
import LiveBroadCast from "./LiveBroadCast";
import ThreeDotPopup from "./ThreeDotPopup";
import { useLocation } from "react-router-dom";

const LiveSection = () => {
	// useProfessionalValidate();
	// const [microPhoneActive, setMicroPhoneActive] = useState(false);
	// const [videoActive, setVideoActive] = useState(false);
	const [callEnd, setCallEnd] = useState(false);
	const [peopleActive, setPeopleActive] = useState(false);
	const [threeDotActive, setThreeDotActive] = useState(false);
	const [text, setText] = useState("");

	const { search } = useLocation();
	
	const ingestEndpoint = search.split("&")[0].split("=")[1];
	const streamKey = search.split("&")[1].split("=")[1];
	
	const handelPostComment = () => {
		console.log(text);
		setText("");
	};

	return (
		<div className="professionalMainContainer">
			{window.innerWidth > 900 && <Header />}

			{peopleActive && <JoinersPopup setShow={setPeopleActive} />}
			{threeDotActive && <ThreeDotPopup setShow={setThreeDotActive} />}
			{callEnd && <EndLivePopup setShow={setCallEnd} />}

			<div className={`ProfessionalBody  ${styles.LiveSection}`}>
				<div className={styles.LeftSection}>
					<LiveBroadCast sKey={streamKey} iServer={ingestEndpoint} />

					{/* <div className={styles.Content}>
						<div className={styles.VideoContainer}>
							<img src="https://picsum.photos/800/800" alt="" />

							<div className={styles.VideoTop}>
								<div className={styles.Left}>
									<div className={styles.imageProfile}>
										<img src="https://picsum.photos/200/300" alt="" />
									</div>
									<div className={styles.textContender}>
										<h5>Rohan Mondal</h5>
										<p>@RohanMondal</p>
									</div>
								</div>
								<div className={styles.Right}>
									<div className={styles.BtnContainer}>
										<button>Live</button>
										<button>
											<AiFillEye /> 12
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.ActionContainer}>
							<button className={styles.Round} onClick={() => setMicroPhoneActive(!microPhoneActive)}>
								{microPhoneActive ? (
									<FaMicrophoneAlt className={styles.Icon} />
								) : (
									<FaMicrophoneAltSlash className={styles.Icon} />
								)}
							</button>
							<button className={styles.Round} onClick={() => setVideoActive(!videoActive)}>
								{videoActive ? <FaVideo className={styles.Icon} /> : <FaVideoSlash className={styles.Icon} />}
							</button>
							<button className={`${styles.Round} ${styles.hangup}`} onClick={() => setCallEnd(true)}>
								<MdCallEnd className={styles.Icon} />
							</button>
							<button className={styles.Round} onClick={() => setPeopleActive(true)}>
								<BsFillPeopleFill className={styles.Icon} />
							</button>
							<button className={styles.Round} onClick={() => setThreeDotActive(true)}>
								<BsThreeDotsVertical className={styles.Icon} />
							</button>
						</div>
					</div> */}
				</div>

				<div className={styles.RightSection}>
					<div className={styles.Content}>
						<h1>Live Comments</h1>

						<div className={styles.CommentContainer}>
							{Array(20)
								.fill("")
								.map((data, index) => (
									<div className={styles.CommentBox} key={index}>
										<img src="https://picsum.photos/200/300" alt="" />
										<div className={styles.textC}>
											<h6>Rohan Mondal</h6>
											<p>Hi mam , How are you ?</p>
										</div>
									</div>
								))}
						</div>

						<div className={styles.CommentInputContainer}>
							<div className={styles.InputContainer}>
								<input
									type="text"
									placeholder="Type your comment"
									value={text}
									onChange={(e) => setText(e.target.value)}
								/>
							</div>
							<button onClick={handelPostComment}>
								<BsFillSendFill />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LiveSection;
