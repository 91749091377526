import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "../css/Support.module.scss";
import axios from "./Hooks/axios";

function SupportEmailPopup({ setShowEmail }) {
	const { user, token } = useSelector((state) => state.auth);
	const [email, setEmail] = useState("");
	const [reason, setReason] = useState("");
	const [message, setMessage] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);

	useEffect(() => {
		setEmail(user?.email !== undefined ? user?.email :"");
	}, [user]);

	const handelSubmit = () => {
		if (reason === "" || message === "" || email === "") return toast.warn("Please fill all the fields !");
		setBtnDisable(true);

		let raw = JSON.stringify({ email, reason, message });
		axios
			.post("support/create", raw, { headers: { "Content-Type": "application/json" } })
			.then(({ data }) => {
				if (data.status) {
					toast.success("Admin will contact you shortly");
					setReason("");
					setMessage("");
				}
			})
			.catch((e) => {
				console.log(e);
				toast.error(e.message);
			});
		setBtnDisable(false);
	};

	return (
		<div className={styles.SupportEmailPopupMainContainer}>
			<div className="">
				<h5 onClick={() => setShowEmail(false)} style={{ cursor: "pointer" }}>
					◄
				</h5>
			</div>

			<div className={`display-flex support ${styles.SupportEmail}`}>
				<h6>Support</h6>
				<input
					type="email"
					placeholder="Email"
					style={{ color: "#fff" }}
					value={email}
					readOnly={token ? true : false}
					onChange={(e) => setEmail(e.target.value)}
				/>{" "}
				<br />
				<input
					type="text"
					placeholder="Type your reason"
					style={{ color: "#fff" }}
					value={reason}
					onChange={(e) => setReason(e.target.value)}
				/>{" "}
				<br />
				<textarea
					placeholder="Message"
					cols="30"
					rows="10"
					style={{ color: "#fff", overflow: "hidden" }}
					value={message}
					onChange={(e) => setMessage(e.target.value)}></textarea>
				<br /> <br />{" "}
				<button className="supportSubmitBtn" disabled={btnDisable} onClick={handelSubmit}>
					Submit
				</button>
			</div>
		</div>
	);
}
export default SupportEmailPopup;
