import React, { useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import { useReactMediaRecorder } from "react-media-recorder";
import styles from "../css/LiveAudio.module.scss";

const LiveAudio = ({ setLiveAudioShow, onImageChange3, audioRecording }) => {
	const audioRef = useRef(null);
	const [isRecording, setIsRecording] = useState(false);
	const { status, startRecording, stopRecording, mediaBlobUrl, mediaBlob } = useReactMediaRecorder({
		audio: true,
		type: "audio/mp3",
	});

	const select = async () => {
		const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
		audioRecording(audioBlob);
		setLiveAudioShow(false);
	};

	return (
		<div className={styles.LiveAudioMainContainer} onClick={() => setLiveAudioShow(false)}>
			<div className={styles.container} onClick={(e) => e.stopPropagation()}>
				<button className={styles.selectAudio} onClick={() => audioRef.current.click()} disabled={isRecording}>
					Select Audio
				</button>
				<input
					type="file"
					name="myfile"
					ref={audioRef}
					accept=".mp3,audio/*"
					onChange={(e) => {
						onImageChange3(e);
						setLiveAudioShow(false);
					}}
					style={{ display: "none" }}
				/>
				<p>or</p>
				{/* <p>{status}</p> */}
				{!isRecording ? (
					<button
						className={styles.recording}
						onClick={(e) => {
							startRecording(e);
							setIsRecording(true);
						}}>
						Record Audio
					</button>
				) : (
					<button
						className={styles.recording}
						onClick={(e) => {
							stopRecording(e);
							setIsRecording(false);
						}}>
						<FaMicrophone size={28} className={styles.rec} />
					</button>
				)}
				{mediaBlobUrl && (
					<>
						<audio src={mediaBlobUrl} controls />
						<button className={styles.selectAudio} onClick={() => select()}>
							Select Recording
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default LiveAudio;
