import React, { useEffect, useRef, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../components/Hooks/axios";
import userDefault from "../images/userDefaultImage.jpg";
import { createNotification } from "./Hooks/CreateNotification";

const Comment = ({ visible, val, setCommentCount }) => {
	const [commentText, setCommentText] = useState("");
	const [comments, setComments] = useState(val?.comments);
	const [cmtReplyState, setCmtReplyState] = useState(false);
	const inputRef = useRef(null);
	const [commentId, setCommentId] = useState("");
	const [commentId2, setCommentId2] = useState("");
	const [commentLoading, setCommentLoading] = useState(false);
	const { userId, user } = useSelector((state) => state.auth);
	const [reload, setReload] = useState(0);
	const [showLess, setShowLess] = useState(false);
	const [threeDotPopupShow, setThreeDotPopupShow] = useState(false);
	const [activeCommentId, setActiveCommentId] = useState("");

	useEffect(() => {
		setCommentLoading(true);
		axios
			.get(`post/get-post-comment/${val?._id}?offset=0`)
			.then(({ data }) => {
				if (data.status) {
					setCommentLoading(false);
					setComments(data.data);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [reload]);

	async function postData(url = "", data = {}, methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token"),
			},
			body: JSON.stringify(data),
		});
		return await response.json();
	}

	function UploadComment() {
		if (commentText === "") return toast.warn("Please add any text!");
		if (cmtReplyState) {
			// createNotification(val?.created_by?._id, "Comment", `${user?.full_name} comment in ${count} for your post`);
			let data = {
				comment_id: commentId,
				post_id: val._id,
				replyComment: {
					user_id: userId,
					comment: commentText,
				},
			};
			postData(`${process.env.REACT_APP_BASE_URL}/post/reply-comment`, data, "POST")
				.then((data) => {
					if (data.status) {
						setCommentText("");
						setCmtReplyState(false);
						setReload(Math.random());
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			createNotification(val?.created_by?._id, "Comment", `${user?.full_name} comment in your post`);
			let data = {
				comment: commentText,
			};
			postData(`${process.env.REACT_APP_BASE_URL}/post/comment-post/${val._id}`, data, "POST").then((data) => {
				if (data.status === true) {
					toast.success("Successfully commented");
					setComments(data.data);
					setCommentText("");
					setCommentCount(data.data.length);
					setReload(Math.random());
				} else {
					console.log("incorrect");
					toast.error(data.message);
				}
			});
		}
	}

	const handelDeleteComment = (cmt) => {
		axios
			.delete(`/post/delete-comment/${cmt.post_id}/${cmt._id}`)
			.then(({ data }) => {
				if (data.status) {
					setReload(Math.random());
					toast.success(data.message);
				}
			})
			.catch((e) => console.log(e));
	};

	function commentsContainer(cmt, i) {
		return (
			<div className="align" key={i}>
				<div className="mainComment">
					<div className="postProfileImageContainer" style={{ marginRight: "2%", marginLeft: "2%" }}>
						{cmt.user_id ? (
							<img src={cmt?.user_id?.pic} alt="" />
						) : (
							<img
								src={userDefault}
								alt="userDefault"
								style={{ marginTop: "0px", width: "100%", height: "100%" }}
							/>
						)}
					</div>
					<div className="cmtPostUserName">
						<h5>{cmt?.user_id?.full_name}</h5>
						<div className="innerPDiv">
							<p className="text-small">{cmt.comment}</p>
						</div>
					</div>
					<button
						className="replyCMT"
						style={{ background: cmtReplyState && commentId === cmt._id ? "#42f" : "" }}
						onClick={() => {
							if (cmtReplyState) inputRef.current.blur();
							else inputRef.current.focus();
							setCmtReplyState(!cmtReplyState);
							setCommentId(cmt?._id);
						}}>
						Reply
					</button>
					{/* {console.log(val.created_by._id === userId)}
					{console.log(cmt?.user_id?._id === userId)} */}
					{(cmt?.user_id?._id === userId || val.created_by._id === userId) && (
						<button
							onClick={() => {
								setThreeDotPopupShow(!threeDotPopupShow);
								setActiveCommentId(cmt?._id);
							}}
							className="commentThreeDot">
							⋮
						</button>
					)}
					{threeDotPopupShow && activeCommentId === cmt?._id && (
						<div className="cmtThreeDotPopup">
							<button
								onClick={() => {
									setThreeDotPopupShow(false);
									handelDeleteComment(cmt);
								}}>
								Delete Comment
							</button>
							{/* <button
								onClick={() => {
									setThreeDotPopupShow(false);
								}}>
								Report
							</button> */}
						</div>
					)}
				</div>

				{showLess && commentId2 === cmt?._id
					? cmt?.replyComment?.map((cmt, index) => (
							<div key={index} className="cmtReplyContainer">
								<div className="cmtReplyImgBox">
									<img src={cmt?.user_id?.pic} alt="" />
								</div>
								<div className="cmtReplyTextBox">
									<h6>{cmt?.user_id?.full_name}</h6>
									<p className="text-small">{cmt?.comment}</p>
								</div>
							</div>
					  ))
					: cmt?.replyComment?.slice(0, 1).map((data, index) => (
							<div key={index} className="cmtReplyContainer">
								<div className="cmtReplyImgBox">
									<img src={data?.user_id?.pic} alt="" />
								</div>
								<div className="cmtReplyTextBox">
									<h6>{data?.user_id?.full_name}</h6>
									<p className="text-small">{data?.comment}</p>
								</div>
							</div>
					  ))}

				{cmt?.replyComment?.length > 1 && (
					<button
						className="showLessBtn"
						onClick={() => {
							setCommentId2(cmt?._id);
							setShowLess(!showLess);
						}}>
						{showLess && commentId2 === cmt?._id ? "show less" : "show more"}
					</button>
				)}
			</div>
		);
	}

	return (
		<div className="comment-area" style={{ display: visible }}>
			{commentLoading ? (
				<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<ColorRing
						visible={true}
						height="100"
						width="100"
						ariaLabel="blocks-loading"
						wrapperClass="blocks-wrapper"
						colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
					/>
				</div>
			) : comments?.length === 0 ? (
				"No comments"
			) : (
				<div className="comments">{comments?.map(commentsContainer)}</div>
			)}
			<div className="display-flex">
				<input
					type="text"
					placeholder="Type your comment.."
					className="comment-box"
					ref={inputRef}
					value={commentText}
					onChange={(e) => setCommentText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") UploadComment();
					}}
				/>
				<button className="comment-btn primary-bg" onClick={UploadComment}>
					✎
				</button>
			</div>
		</div>
	);
};

export default Comment;
