// import { GoogleLogin } from "@leecheuk/react-google-login";
import "bootstrap/dist/css/bootstrap.min.css";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { saveUser } from "../Redux/slices/AuthSlice";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";

function GetStarted() {
	useValidateWithoutLogin();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	async function postData(url = "", data = {}, methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	function handelCallBackResponse(response) {
		let userObject = jwt_decode(response.credential);
		let data = {
			email: userObject.email,
			password: userObject.jti,
			...(userObject.family_name
				? { full_name: userObject.given_name + " " + userObject.family_name }
				: { full_name: userObject.given_name }),
			image: userObject.picture,
			user_type: "user",
		};
		postData(process.env.REACT_APP_BASE_URL + "/auth/google-register", data, "POST").then((data) => {
			if (data.status == true) {
				dispatch(saveUser({ ...data, rememberMe: true }));
				// navigate("/dashboard", { replace: true });
				window.open("/dashboard", "_self");
			} else {
				swal("incorrect credentials");
			}
		});
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			window.google.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				callback: handelCallBackResponse,
			});
			window.google.accounts.id.renderButton(document.getElementById("signInDiv"), {
				them: "outline",
				size: "medium",
				width: 190,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<div className="GetStarted">
			<div className="left">
				<div className="pgNo">01</div>
				<div className="leftHeading">Learn Music</div>
				<div className="dots">
					<div className="dot-white"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<h1 className="card-title">Welcome</h1>
					<button onClick={() => navigate("/login")} className="loginWithEmail" style={{ width: "200px" }}>
						Login with Email
					</button>
					<br />
					{/* <button onClick={() => navigate("/mobile-login")} className="loginWithMobile" style={{ width: "200px" }}>
						Login with Mobile
					</button> */}
					<br />
					<button className="loginWithGoogle" style={{ width: "200px" }}>
						<div id="signInDiv"></div>
						Login with Google
					</button>
					{/* <button id="signInDiv" className={"g_id_signin"} style={{ overflow: "hidden", background: "transparent" }} /> */}
					<p className="card-text">
						Don't Have An Account? <a href="/register1">Create New</a>
					</p>
				</div>
			</div>
		</div>
	);
}
export default GetStarted;
