import { motion } from "framer-motion";
import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import styles from "../../css/LiveClassPopup.module.scss";
import s1 from "../../images/Ellipse 100.png";
import s2 from "../../images/Ellipse 101.png";
import s3 from "../../images/Ellipse 102.png";
import s4 from "../../images/Ellipse 103.png";
import s5 from "../../images/Ellipse 104.png";
import SendLinkStudentPopup from "./SendLinkStudentPopup";
import { toast } from "react-toastify";

const ThreeDotPopup = ({ setShow }) => {
	const [sharePopup, setSharePopup] = useState(false);
	const [sendLinkPopup, setSendLinkPopup] = useState(false);

	return (
		<div onClick={() => setShow(false)} className={styles.PopupBackground}>
			{!sharePopup && !sendLinkPopup && (
				<motion.div
					initial={{ y: "-100px" }}
					animate={{ y: "0px" }}
					className={styles.ThreeDotPopup}
					onClick={(e) => e.stopPropagation()}>
					<RxCrossCircled className={styles.cross} onClick={() => setShow(false)} />
					<p onClick={() => setSendLinkPopup(true)}>Send your lecture to students</p>
					<p onClick={()=>toast.success("Link Copied")}>Copy live link </p>
					<p onClick={() => setSharePopup(true)}>Share </p>
				</motion.div>
			)}

			{sharePopup && (
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					className={styles.SharePopup}
					onClick={(e) => e.stopPropagation()}>
					<RxCrossCircled className={styles.cross} onClick={() => setSharePopup(false)} />
					<h1>Share on</h1>
					<div className={styles.SocialMediaIcon}>
						<img src={s1} alt="FaceBook" />
						<img src={s2} alt="SnapChat" />
						<img src={s3} alt="WhatsApp" />
						<img src={s4} alt="Copy Link" />
						<img src={s5} alt="Instagram" />
					</div>
				</motion.div>
			)}

			{sendLinkPopup && <SendLinkStudentPopup setShow={setSendLinkPopup} />}
		</div>
	);
};

export default ThreeDotPopup;
