import React, { useEffect, useRef, useState } from "react";
import { isChrome, isDesktop, isFirefox } from "react-device-detect";
import styles from "../../css/Broadcast.module.css";

import ControlBar from "./components/ControlBar";
import useScreenShare from "./components/ScreenShare";
import StatusBar from "./components/StatusBar";
import StreamPreview, { useLayers, useMixer } from "./components/StreamPreview";

import { useAlertBox } from "./components/AlertBox";
import { getConfigFromResolution } from "./components/Helpers";
import useStream from "./components/Stream";

const CAM_LAYER_NAME = "camera";
const MIC_LAYER_NAME = "mic";

const LiveBroadCast = ({ sKey, iServer }) => {
	const client = useRef(null);
	const canvasRef = useRef(null);
	const channelType = useRef("STANDARD");
	const streamResolution = useRef("720");
	const [devicePermissions, setDevicePermissions] = useState({
		video: false,
		audio: false,
	});
	const [ingestServer, setIngestServer] = useState(iServer); //NOTE:
	const [streamKey, setStreamKey] = useState(sKey); //NOTE:

	const { updateLayer, addLayer, removeLayer, resetLayers } = useLayers([]);

	const { addMixerDevice, addAudioTrack, removeMixerDevice, toggleMixerDeviceMute, resetMixer } = useMixer([]);

	const { captureStream, startScreenShare, stopScreenShare } = useScreenShare();

	const { isLive, streamLoading, toggleStream } = useStream();

	const activeVideoDevice = useRef(null);
	const activeAudioDevice = useRef(null);

	const [camMuted, setCamMuted] = useState(false);
	const [micMuted, setMicMuted] = useState(false);

	const [errorAlertActive, toggleErrorAlert] = useAlertBox();
	const [errorAlertMessage, setErrorAlertMessage] = useState("");

	const isSupported = isDesktop && (isFirefox || isChrome);
	const [showAlert, setShowAlert] = useState(!isSupported);

	const handleError = (message) => {
		toggleErrorAlert();
		setErrorAlertMessage(`${message}`);
		console.error(message);
	};

	const handlePermissions = async () => {
		try {
			// Width and height are set to attempt to get max resolution video feed
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: true,
			});
			for (const track of stream.getTracks()) {
				track.stop();
			}
			setDevicePermissions({ video: true, audio: true });
		} catch (err) {
			setDevicePermissions({ video: false, audio: false });
			throw Error(err);
		}
	};

	const handleStream = async () => {
		if (ingestServer && streamKey) {
			toggleStream(ingestServer, streamKey, channelType.current, client.current, handleError);
		}
	};

	const handleMicMute = async () => {
		const mixerDevice = {
			name: MIC_LAYER_NAME,
			device: activeAudioDevice.current,
			muted: micMuted,
		};

		const muted = toggleMixerDeviceMute(mixerDevice, client.current);
		setMicMuted(muted);
	};

	const handleCameraMute = async () => {
		const canvas = client.current.getCanvasDimensions();

		let camLayer = {
			device: activeVideoDevice.current,
			name: CAM_LAYER_NAME,
			index: 4,
			visible: camMuted,
			x: 0,
			y: 0,
			width: canvas.width,
			height: canvas.height,
			type: "VIDEO",
		};

		// captureStream?.active will be truthy if screensharing is active.
		if (captureStream?.active) {
			const padding = 20;
			camLayer = {
				...camLayer,
				x: canvas.width - canvas.width / 4 - padding,
				y: canvas.height - canvas.height / 4 - padding,
				width: canvas.width / 4,
				height: canvas.height / 4,
				type: "VIDEO",
			};
		}

		if (camMuted) {
			await addLayer(camLayer, client.current);
			setCamMuted(false);
		} else {
			await removeLayer(camLayer, client.current);
			setCamMuted(true);
		}
	};

	const handleScreenShare = async () => {
		// If the SDK client is not available, throw an error
		if (!client.current) {
			handleError(`Screen share error: Broadcast SDK is not available.`);
			return;
		}

		const canvas = client.current.getCanvasDimensions();

		// Toggle the state of the active screen share
		try {
			if (captureStream?.active) {
				await stopScreenShare(
					activeVideoDevice.current,
					camMuted,
					removeLayer,
					removeMixerDevice,
					updateLayer,
					client.current
				);
			} else {
				await startScreenShare(
					activeVideoDevice.current,
					camMuted,
					updateLayer,
					addLayer,
					removeLayer,
					removeMixerDevice,
					addAudioTrack,
					canvas,
					client.current
				);
			}
		} catch (err) {
			handleError(`Screen share error: ${err.message}`);
		}
	};

	const getVideoDevices = async () => {
		try {
			const devices = await navigator?.mediaDevices?.enumerateDevices();
			const videoDevices = devices.filter((device) => device.kind === "videoinput");
			if (!videoDevices.length) {
				throw Error("No video devices found.");
			}
			return videoDevices;
		} catch (err) {
			throw Error(err);
		}
	};

	const getAudioDevices = async () => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const audioDevices = devices.filter((device) => device.kind === "audioinput");
			if (!audioDevices.length) {
				throw Error("No audio devices found.");
			}
			return audioDevices;
		} catch (err) {
			throw Error(err);
		}
	};

	// Initialize canvas layers...
	const initLayers = async () => {
		// If the user has not provided permissions, get them.
		if (!devicePermissions.video) {
			try {
				await handlePermissions();
			} catch (err) {
				// If we still don't have permissions after requesting them display the error message
				if (!devicePermissions.video && !devicePermissions.audio) {
					handleError(
						"Failed to access the camera and microphone. To start streaming, you must allow access to both your camera and microphone"
					);
				}
			}
		}

		// Log errors in the browser console
		client.current.config.logLevel = client.current.config.LOG_LEVEL.ERROR;

		// Attach the preview canvas to the client
		client.current.attachPreview(canvasRef.current);

		const canvas = client.current.getCanvasDimensions();
		const camOffLayer = {
			name: "camOff",
			imageSrc: "/assets/camera-off.png",
			index: 1,
			x: canvas.width / 2 - canvas.width / 16,
			y: canvas.height / 2 - canvas.width / 16,
			width: canvas.width / 8,
			height: canvas.width / 8,
			type: "IMAGE",
		};

		try {
			await addLayer(camOffLayer, client.current);
		} catch (err) {
			handleError("Error: Failed to add a layer to the canvas. If the problem persists, try refreshing the app.");
		}

		try {
			// Get video devices
			var vd = await getVideoDevices(client.current);

			// Get audio devices
			var ad = await getAudioDevices(client.current);
		} catch (err) {
			console.error(err);
			handleError(
				"Error: Could not find any available video or audio devices. Please ensure that a camera or microphone is attached to your device, and your privacy settings allow this app access them."
			);
		}

		// Fetch saved devices from localstorage
		const savedVideoDeviceId = localStorage.getItem("savedVideoDeviceId");
		const savedAudioDeviceId = localStorage.getItem("savedAudioDeviceId");
		try {
			// If there is not active video device, set the default video device as the active device
			if (!activeVideoDevice.current) {
				const savedVideoDevice = vd?.find((device) => device.deviceId === savedVideoDeviceId);
				activeVideoDevice.current = savedVideoDevice ? savedVideoDevice : vd[0];
			}
			// Render the video device on the broadcast canvas
			renderActiveVideoDevice();

			// If there is no active audio device, set the default audio device as the active device
			if (!activeAudioDevice.current) {
				const savedAudioDevice = ad?.find((device) => device.deviceId === savedAudioDeviceId);
				activeAudioDevice.current = savedAudioDevice ? savedAudioDevice : ad[0];
			}
			// Add the active audio device to the broadcast mixer
			renderActiveAudioDevice();
		} catch (err) {
			console.error(err);
			handleError(
				"Error: Could not add the selected audio and video devices to the canvas. Please check the app settings to ensure that the correct webcam and microphone are selected."
			);
		}
	};

	// Handle active video device (webcam) changes...
	const renderActiveVideoDevice = () => {
		const canvas = client.current.getCanvasDimensions();
		const deviceToAdd = activeVideoDevice.current;

		let layer = {
			device: deviceToAdd,
			name: CAM_LAYER_NAME,
			index: 4,
			visible: !camMuted,
			x: 0,
			y: 0,
			width: canvas.width,
			height: canvas.height,
			type: "VIDEO",
		};

		// captureStream?.active is truthy if a screenshare is active.
		if (captureStream?.active) {
			const padding = 20;
			layer = {
				...layer,
				x: canvas.width - canvas.width / 4 - padding,
				y: canvas.height - canvas.height / 4 - padding,
				width: canvas.width / 4,
				height: canvas.height / 4,
				type: "VIDEO",
			};
		}

		addLayer(layer, client.current);
	};

	// Handle microphone device changes
	const renderActiveAudioDevice = () => {
		const mixerDevice = {
			name: MIC_LAYER_NAME,
			device: activeAudioDevice.current,
			muted: micMuted || false,
		};
		addMixerDevice(mixerDevice, client.current);
	};

	useEffect(() => {
		const streamConfig = getConfigFromResolution(streamResolution.current, channelType.current);
		const IVSClient = window.IVSBroadcastClient.create({
			streamConfig: streamConfig,
		});
		client.current = IVSClient;
		initLayers();
	}, []);

	return (
		<>
			<div className={styles.broadcastWrapper}>
				{/* <AlertBar
					handleClose={() => {
						setShowAlert(false);
					}}
					show={showAlert}
					message={
						"This browser is not fully supported by this tool. For best results, open this page on a PC or Mac in Google Chrome or Firefox."
					}
				/> */}
				<div className={styles.statusBar}>
					<StatusBar isLive={isLive} streamResolution={streamResolution.current} />
				</div>
				<div className={styles.streamPreview}>
					<StreamPreview canvasRef={canvasRef} videoPermissions={devicePermissions.video} />
				</div>
				<div className={styles.controlBar}>
					<div className={styles.controlBarLeft}></div>
					<div className={styles.controlBarCenter}>
						<ControlBar
							videoPermissions={devicePermissions.video}
							isLive={isLive}
							streamLoading={streamLoading}
							isDesktop={isDesktop}
							micMuted={micMuted}
							camMuted={camMuted}
							screenShareActive={captureStream?.active}
							handleScreenShare={handleScreenShare}
							handleMicMute={handleMicMute}
							handleCameraMute={handleCameraMute}
							handleStream={handleStream}
						/>
					</div>
				</div>
			</div>
			{/* <AlertBox type="error" show={errorAlertActive} onClose={toggleErrorAlert}>
				{errorAlertMessage}
			</AlertBox> */}
		</>
	);
};

export default LiveBroadCast;
