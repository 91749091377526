import { motion } from "framer-motion";
import React, { useState } from "react";
import axios from "../../components/Hooks/axios";
import styles from "../../css/LiveClassPopup.module.scss";
import { toast } from "react-toastify";

const AddLecture = ({ setShow, classId, setReload }) => {
	const [date, setDate] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [start, setStart] = useState("");
	const [end, setEnd] = useState("");

	const handelAddLecture = () => {
		const startDate = new Date(`${date} ${start}`);
		const endDate = new Date(`${date} ${end}`);
		const timeDifferenceMs = endDate - startDate;
		const minutes = Math.floor(timeDifferenceMs / 60000);
		const id = toast.loading("Please wait...");

		var formData = new FormData();
		formData.append("class_id", classId);
		formData.append("lectureTitle", title);
		formData.append("lectureDesc", description);
		formData.append("lectureDate", date);
		formData.append("lectureDuration", minutes);

		axios
			.post("/class/addLiveLacture", formData)
			.then(({ data }) => {
				console.log(data);
				setShow(false);
				setReload(Math.random());
				toast.update(id, {
					render: "Your Lecture add successfully",
					type: "success",
					isLoading: false,
					autoClose: 2000,
				});
			})
			.catch((e) => console.log(e));
	};

	return (
		<div onClick={() => setShow(false)} className={styles.PopupBackground}>
			<motion.div
				initial={{ y: "-500px" }}
				animate={{ y: "0px" }}
				className={styles.AddLecture}
				onClick={(e) => e.stopPropagation()}>
				<h1>Add lecture </h1>
				<p>Please fill the Details.</p>

				<div className={styles.InputContainer}>
					<label>Title of class</label>
					<input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
				</div>

				<div className={styles.InputContainer}>
					<label>Set a date</label>
					<input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
				</div>

				<div className={styles.InputContainer}>
					<label>Time Duration</label>

					<div className={styles.timeContainer}>
						<p>From :</p>
						<input type="time" className={styles.time} value={start} onChange={(e) => setStart(e.target.value)} />
						<p>To :</p>
						<input type="time" className={styles.time} value={end} onChange={(e) => setEnd(e.target.value)} />
					</div>
				</div>

				<div className={styles.InputContainer}>
					<label>Description </label>
					<textarea defaultValue={description} onChange={(e) => setDescription(e.target.value)}></textarea>
				</div>

				<button onClick={handelAddLecture}>Done</button>
			</motion.div>
		</div>
	);
};

export default AddLecture;
