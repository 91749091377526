import "bootstrap/dist/css/bootstrap.min.css";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { saveUser } from "../Redux/slices/AuthSlice";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import logo from "../images/logoM.png";
import axios from "../components/Hooks/axios";

function GetStartedProfessional() {
	useValidateWithoutLogin();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	async function postData(url = "", data = {}) {
		const response = await axios.post(url,data)
		return response.data;
	}

	function handelCallBackResponse(response) {
		let userObject = jwt_decode(response.credential);
		let data = {
			email: userObject.email,
			password: userObject.jti,
			...(userObject.family_name
				? { full_name: userObject.given_name + " " + userObject.family_name }
				: { full_name: userObject.given_name }),
			image: userObject.picture,
			user_type: "professional",
		};
		postData("/auth/google-register", data).then((data) => {
			if (data.status == true) {
				dispatch(saveUser({ ...data, rememberMe: true }));
				console.log(data)
				// navigate("../professional-home", { replace: true });
				window.open("/Professional-home", "_self");
			} else {
				console.log(data);
				swal("incorrect credentials");
			}
		});
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			window.google.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				callback: handelCallBackResponse,
			});
			window.google.accounts.id.renderButton(document.getElementById("signInDiv"), {
				them: "outline",
				size: "medium",
				width: 190,
			});
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<>
			<div className="Professional-GetStarted">
				<div className="header">
					<div className="display-flex" style={{ alignItems: "center" }}>
						<img src={logo} alt="" style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
						<div>
							<h5>Music book</h5>
							<p>Live every beat of your life</p>
						</div>
					</div>

					<div className="display-flex professionalGetStartedButtonContainer">
						<button className="SIGN" onClick={() => navigate("/Professional-login")}>
							SIGN IN
						</button>
						<button
							// className="btn btn-primary"
							className="CA"
							onClick={() => navigate("/Professional-register1")}>
							CREATE ACCOUNT
						</button>
						<button className="SIG">
							<div id="signInDiv"></div>
							SIGN IN WITH GOOGLE
						</button>
					</div>
				</div>

				<h1>Teach music across globe and earn money</h1>
				<div className="dots" style={{ marginLeft: "10%" }}>
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>
		</>
	);
}
export default GetStartedProfessional;
