import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import swal from "sweetalert";
import eye from "../images/eye-solid.svg";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../Redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import { BiLockAlt } from "react-icons/bi";

function Login() {
	useValidateWithoutLogin();
	const navigate = useNavigate()
	const dispatch = useDispatch();

	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState("");
	const [Pass, setPass] = useState("");
	const [rememberMe, setRememberMe] = useState(true);

	async function postData(url = "", data = {}, methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	function LoginBtn() {
		if (email == "" || Pass == "") {
			swal("Please Enter Correct Credentials");
			return;
		}
		setLoader(true);

		let data = {
			email: email,
			password: Pass,
			user_type: "user",
		};

		postData(process.env.REACT_APP_BASE_URL + "/auth/login", data, "POST").then((data) => {
			if (data.status == true) {
				setLoader(false);
				dispatch(saveUser({ ...data, rememberMe }));
				window.open("/dashboard", "_self");
				// navigate("../dashboard", { replace: true });
			} else {
				setLoader(false);
				swal("incorrect credentials");
			}
		});
	}

	const [eyeV, setEyeV] = useState("password");
	function visible() {
		if (eyeV == "password") setEyeV("text");
		else setEyeV("password");
	}

	return (
		<div className="login">
			<div className="left">
				<div className="leftHeading">Live every beat of your life</div>
				<br />
				<br />
				<br />
				<br />
				<div className="dots">
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="card">
				<div className="card-body LoginUserContainer">
					<h1 className="card-title">LOGIN</h1>
					<input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
					<div className="passContainer">
						<input
							className="eye-pass"
							type={eyeV}
							placeholder="Password"
							onChange={(e) => setPass(e.target.value)}
						/>
						<BiLockAlt className="lockLogin"/>
						<img src={eye} className="eyeImage" onClick={visible} style={{ cursor: "pointer" }} alt="" />
					</div>
					<div className="CheckBoxContainer">
						<div className="check-box-remember">
							<input
								type="checkbox"
								className="LogCheck"
								value={rememberMe}
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
							/>
						</div>
						<p className="card-text">
							Remember me &nbsp;&nbsp;<a href="/forget-password">Forgot Password </a>
						</p>
					</div>
					<button
						className="loginWithEmail"
						onClick={LoginBtn}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						{loader ? <div className="ring"></div> : <>Continue</>}
					</button>
					<p className="card-text">
						<a href="/register1">Create an account</a>
					</p>
				</div>
			</div>
		</div>
	);
}
export default Login;
