import React, { useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import sweetAlert from "sweetalert";
import share from "../images/share.png";
import axios from "./Hooks/axios";

const SharePostScreen = () => {
	let postId = useParams();
	const navigate = useNavigate();

	const [imgUrl, setImgUrl] = useState("");
	const [caption, setCaption] = useState("");
	const [name, setName] = useState("");
	const [likeCount, setLikeCount] = useState("");
	const [type, setType] = useState("");
	const [comments, setComments] = useState([]);
	const [profileImage, setProfileImage] = useState("");
	const [isLiked, setIsLiked] = useState("");
	const [disable, setDisable] = useState(false);
	const [commentText, setCommentText] = useState("");
	const [ReadMore, setReadMore] = useState(false);
	const [PostRating, setPostRating] = useState("");
	const [ratingCount, setRatingCount] = useState("");

	useEffect(() => {
		axios.get(`post/get-post/${postId.id}`).then(({ data }) => {
			console.log(data.data.data[0]);
			if (data.status == true) {
				setProfileImage(data.data?.data[0]?.created_by?.pic);
				setCaption(data?.data?.data[0]?.captions);
				setName(data?.data?.data[0]?.created_by?.full_name);
				setLikeCount(data?.data?.data[0]?.likes_count);
				setIsLiked(data?.data?.data[0]?.is_like);
				setComments(data?.data?.data[0]?.comments);
				setPostRating(data?.data?.data[0]?.rating);
				setRatingCount(data?.data?.data[0]?.rating_data[0]?.totalrating);

				if (data?.data?.data[0]?.media?.length != 0) {
					setImgUrl(data?.data?.data[0]?.media[0]?.file);
					setType(data?.data?.data[0]?.media[0]?.type);
				} else setType("text");
			} else {
				console.log("incorrect");
			}
		});
	}, []);

	const likeClick = (postId) => {
		setDisable(true);
		if (localStorage.getItem("auth_token")) {
			if (likeCount < 0) return;
			axios
				.post("/post/like-post/" + postId)
				.then(({ data }) => {
					console.log(data.message);
					console.log(data.data.likes_count);
					console.log(data);
					if (data.status == true) {
						setLikeCount(data.data.likes_count);
						if (data.message == "post liked") setIsLiked(true);
						if (data.message == "post unlike") setIsLiked(false);
						setDisable(false);
					}
				})
				.catch((e) => {
					console.log(e);
					setDisable(false);
				});
		} else {
			sweetAlert("Login first");
		}
	};

	const userNotLogin = () => {
		sweetAlert({
			title: "You are not logged in!!",
			text: "Please Login or Sign up first",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((confirmed) => {
			if (confirmed) {
				if (isMobile) {
					window.open("https://play.google.com/store/apps/details?id=com.music.book", "_self");
				}
				if (isBrowser) {
					navigate("/login");
				}
			}
		});
	};

	const uploadComment = () => {
		let data = {
			comment: commentText,
		};
		axios.post("/post/comment-post/" + postId.id, data).then(({ data }) => {
			if (data.status === true) {
				sweetAlert("Successfully commented");
				setComments(data.data);
				setCommentText("");
				console.log("data= > ", data);
			} else {
				console.log("incorrect");
				sweetAlert("data.message");
			}
		});
	};

	return (
		<div className="SharePostScreen">
			<div className="SharePostLeft">
				<div className="SharePost">
					{type == "image" ? (
						<div className="imageVideoContainer">
							<div className="SharePostImageContainer">
								<img src={imgUrl} alt="" />
							</div>
							<p>{caption}</p>
						</div>
					) : type == "text" ? (
						<div className="textContainer">
							{ReadMore ? (
								<>
									{caption}
									<button type="button" onClick={() => setReadMore(false)}>
										{" "}
										...Read Less
									</button>
								</>
							) : (
								<>
									{caption.slice(0, 240)}
									{caption.length > 240 ? (
										<button type="button" onClick={() => setReadMore(true)}>
											{" "}
											... Read More
										</button>
									) : (
										""
									)}
								</>
							)}
						</div>
					) : type == "video" ? (
						<div className="imageVideoContainer">
							<div className="SharePostImageContainer">
								<video src={imgUrl} controls autoPlay={true} alt="" />
							</div>
							<p>{caption}</p>
						</div>
					) : type == "audio" ? (
						<div className="imageVideoContainer">
							<div className="SharePostImageContainer" style={{ paddingTop: "20px" }}>
								<div className="waves display-flex">
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
									<div className="music-wave"></div>
								</div>
								<audio autoPlay src={imgUrl} controls></audio>
							</div>
							<p>{caption}</p>
						</div>
					) : null}
					<div className="ShareProfileControls">
						<div className="ShareProfileControlsLeft">
							<img src={profileImage} alt="" />
							<div className="sharePContWrapper">
								<p>{name}</p>
								<div className="shareRatingCont">
									{Array.from({ length: 5 }, (_, index) => {
										let starHalf = index + 0.5;
										return PostRating >= index + 1 ? (
											<FaStar key={index} color={"#FFA800"} />
										) : PostRating >= starHalf ? (
											<FaStarHalfAlt key={index} color={"#FFA800"} />
										) : (
											<FaRegStar key={index} color={"#FFA800"} />
										);
									})}
									<p>{PostRating}</p>
								</div>
							</div>
						</div>
						<div className="ShareProfileControlsRight">
							<div className="ShareLike">
								{localStorage.getItem("auth_token") ? (
									<button
										className="ShareHeart"
										disabled={disable}
										onClick={() => likeClick(postId.id)}
										style={{
											color: isLiked ? "red" : "#fff",
										}}>
										<FaStar color={"#FFA800"} />
									</button>
								) : (
									<button
										className="ShareHeart"
										onClick={userNotLogin}
										style={{
											color: "red",
										}}>
										<FaStar color={"#FFA800"} />
									</button>
								)}
								<p className="text-white">{ratingCount}</p>
							</div>
							<div className="ShareIconContainer">
								<RWebShare
									data={{
										text: "MusicBook",
										url: "/share/" + postId.id,
										title: "MusicBook",
									}}
									onClick={() => console.log("shared successfully!")}>
									<img src={share} alt="share" />
								</RWebShare>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="SharePostRight">
				<h1>Comments</h1>
				<div className="SharePostCommentsContainer">
					{comments.length != 0 ? (
						comments.map((item, index) => (
							<div className="SharePostComment" key={index}>
								<div>
									<img src={item.user_id.pic} alt="" />
									<p>{item.user_id.full_name}</p>
								</div>
								<p>{item.comment}</p>
							</div>
						))
					) : (
						<h1>No Comments Yet</h1>
					)}
				</div>
				<div className="SharePostWriteBox">
					<input
						type="text"
						placeholder="Type your comment.."
						value={commentText}
						onChange={(e) => setCommentText(e.target.value)}
					/>
					{localStorage.getItem("auth_token") ? (
						<button onClick={uploadComment}>✎</button>
					) : (
						<button onClick={userNotLogin}>✎</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default SharePostScreen;
