import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import AudioPost from "./AudioPost";
import CaptionPost from "./CaptionPost";
import ImagePost from "./ImagePost";
import MessagePopup from "./MessagePopup";
import VideoPost from "./VideoPost";

function Post({ page, setPage, reload, setReload }) {
	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	const [show, setShow] = useState(false);
	const [postId, setPostId] = useState("");
	const [otherUserPopup, setOtherUserPopup] = useState(false);
	const [post, setPost] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [noPost, setNoPost] = useState(false);
	const [visible, setVisible] = useState("none");
	const { isMessageOpen } = useSelector((state) => state.temp);

	useEffect(() => {
		if (reload) {
			setPage(0);
			setPost([]);
		}
		setIsLoading(true);
		getData(`${process.env.REACT_APP_BASE_URL}/post/home-data?offset=${reload ? 0 : page}&type=`, "GET").then((data) => {
			if (data.status == true) {
				setPost((prev) => [...prev, ...data.data]);
				setReload(false);
				setIsLoading(false);
				if (data.data.length == 0) setNoPost(true);
				console.log("post data =", data);
			} else {
				console.log("incorrect");
			}
		});
	}, [page, reload]);

	let i = 0;
	function NCards(val) {
		let imgUrl = "";
		let postType = "";
		const mediaArr = [];

		for (let i = 0; i < val.media.length; i++) {
			mediaArr.push(val.media[i].file);
		}
		if (val.media[0]) {
			imgUrl = val.media[0].file;
			postType = val.media[0].type;
		}

		function Visible() {
			setPostId(val._id);
			if (visible == "block") setVisible("none");
			else setVisible("block");
		}

		if (val?.created_by != null) {
			if (val.media[0]) {
				if (postType == "image") {
					return (
						<ImagePost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							mediaArr={mediaArr}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				} else if (postType == "video") {
					return (
						<VideoPost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							imgUrl={imgUrl}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				} else if (postType == "audio") {
					return (
						<AudioPost
							key={val._id + i++}
							val={val}
							visible={visible}
							Visible={Visible}
							post={post}
							show={show}
							setShow={setShow}
							postId={postId}
							setPostId={setPostId}
							imgUrl={imgUrl}
							setReload={setReload}
							setPage={setPage}
							otherUserPopup={otherUserPopup}
							setOtherUserPopup={setOtherUserPopup}
						/>
					);
				}
			} else {
				return (
					<CaptionPost
						key={val._id + i++}
						val={val}
						visible={visible}
						Visible={Visible}
						post={post}
						show={show}
						setShow={setShow}
						postId={postId}
						setPostId={setPostId}
						setReload={setReload}
						setPage={setPage}
						otherUserPopup={otherUserPopup}
						setOtherUserPopup={setOtherUserPopup}
					/>
				);
			}
		}
	}

	return (
		<>
			{post.map(NCards)}
			<div style={{ height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
				{isLoading ? (
					<ColorRing
						visible={true}
						height="90"
						width="90"
						ariaLabel="blocks-loading"
						wrapperClass="blocks-wrapper"
						colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
					/>
				) : (
					noPost && <h5>No More Post Available</h5>
				)}
			</div>

			{isMessageOpen && <MessagePopup />}
		</>
	);
}
export default Post;
