import axios from "../Hooks/axios";
import React, { useRef, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import styles from "../../css/Payment.module.scss";
import logo from "../../images/logoM.png";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Payment = ({ setPayment }) => {
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [country, setCountry] = useState("");
	const [tell, setTell] = useState("");
	const [email, setEmail] = useState("");
	const [accessCode, setAccessCode] = useState("");
	const [encRequest, setEncRequest] = useState("");
	const fromBtnRef = useRef(null);
	const [btnDisable, setBtnDisable] = useState(false);

	const data = useParams()
	// console.log(data)

	const cardId = "645bb20b7186d66f8615066f";
	const amount = "1.00";
	const premium = true;
	const token =
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGMwMWMwZDI5ODBjYjIwMmFiMjFhNTMiLCJpYXQiOjE2OTAzNTc2NjMsImV4cCI6MTY5NTU0MTY2M30.jLcZCHY1AkD3QwTPitqHx4Tj0llZ8E9T5gw1c1tQjEI";

	const handelPay = () => {
		if (
			name === "" ||
			address === "" ||
			city === "" ||
			state === "" ||
			zip === "" ||
			country === "" ||
			tell === "" ||
			email === ""
		)
			return toast.warn("Please fill all the fields !");

		setBtnDisable(true);

		const data = JSON.stringify({
			amount: amount,
			is_premium: premium,
			redirect_url: "https://stage.musicbook.co.in/api/v1/ccavResponseHandler",
			cancel_url: "https://stage.musicbook.co.in/api/v1/ccavResponseHandler",
			customer_detail: {
				billing_name: name,
				billing_address: address,
				billing_city: city,
				billing_state: state,
				billing_zip: zip,
				billing_country: country,
				billing_tel: tell,
				billing_email: email,
				delivery_name: name,
				delivery_address: address,
				delivery_city: city,
				delivery_state: state,
				delivery_zip: zip,
				delivery_country: country,
				delivery_tel: tell,
			},
		});

		axios
			.post(`https://stage.musicbook.co.in/api/v1/order/create-cc-order/${cardId}`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			})
		// axios
		// 	.get(`https://stage.musicbook.co.in/api/v1/order/get-cc-order/${"64c273fada28ae37bf023837"}`, {
		// 		headers: {
		// 			Authorization: token,
		// 		},
		// 	})
			.then(({ data }) => {
				if (data.status) {
					console.log(data);
					setAccessCode(data.data.accessCode);
					setEncRequest(data.data.encRequest);
					setBtnDisable(false);
					setTimeout(() => {
						fromBtnRef.current.click();
					}, 10);
				}
			})
			.catch((e) => {
				setBtnDisable(false);
				console.log("Error=> ", e);
			});
	};

	return (
		<div className={styles.PaymentAddressContainer}>
			<div className={styles.PaymentAddress}>
				<div className={styles.Top}>
					<button onClick={() => setPayment(false)}>
						<BsArrowLeft />
					</button>
					<button onClick={() => setPayment(false)}>
						<RxCross2 />
					</button>
				</div>

				<form
					id="nonseamless"
					style={{ display: "none" }}
					method="post"
					name="redirect"
					action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
					<input type="hidden" id="encRequest" name="encRequest" value={encRequest} />
					<input type="hidden" id="access_code" name="access_code" value={accessCode} />
					<button type="submit" ref={fromBtnRef}></button>
				</form>

				<div className={styles.Bottom}>
					<div className={styles.BottomTop}>
						<div className={styles.t}>
							<img src={logo} alt="" />
							<div>
								<h3>Music book</h3>
								<p>Live every beat of your life</p>
							</div>
						</div>
						<h2>Payment method</h2>
					</div>
					<div className={styles.Form}>
						<div className={styles.Left}>
							<div className={styles.InputContainer}>
								<label>Name</label>
								<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
							</div>
							<div className={styles.InputContainer}>
								<label>Address</label>
								<input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
							</div>
							<div className={`${styles.InputContainer} ${styles.two}`}>
								<div>
									<label>City</label>
									<input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
								</div>
								<div>
									<label>State</label>
									<input type="text" value={state} onChange={(e) => setState(e.target.value)} />
								</div>
							</div>
							<div className={styles.InputContainer}>
								<label>Zip</label>
								<input type="number" value={zip} onChange={(e) => setZip(e.target.value)} />
							</div>
						</div>
						<div className={styles.Right}>
							<div className={styles.InputContainer}>
								<label>Country</label>
								<input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
							</div>
							<div className={styles.InputContainer}>
								<label>Phone</label>
								<input type="number" value={tell} onChange={(e) => setTell(e.target.value)} />
							</div>
							<div className={styles.InputContainer}>
								<label>Email</label>
								<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
							</div>
							<button className={styles.button} disabled={btnDisable} onClick={handelPay}>
								{btnDisable ? (
									<RotatingLines
										strokeColor="#3300ff"
										strokeWidth="5"
										animationDuration="0.75"
										width="40"
										visible={true}
									/>
								) : (
									"Pay Now"
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payment;
