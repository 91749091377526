import React from "react";

export const ProfileImagePopup = ({ ProfileImage, setShowProfilePic }) => {
	return (
		<div className="ProfileImagePopup">
			<button type="button" onClick={() => setShowProfilePic(false)}>
				X
			</button>
			<div className="ProfileImagePopupImage">
				<img src={ProfileImage} alt="" />
			</div>
		</div>
	);
};
