import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useDeleteAlbumMutation, useDeleteSongMutation } from "../../Redux/api/albumApi";
import { setCreated, setCurrentSong } from "../../Redux/slices/AlbumSlice";
import axios from "../../components/Hooks/axios";
import arrowIcon from "../../images/arrowIcon.png";
import paly2 from "../../images/play2.png";
import { setAlbumChange } from "../../Redux/slices/TempSlice";

const AlbumPopup = ({
	setShowAlbumPopup,
	createdAlbumId,
	setShowAddSongPopup,
	reloadSongComponent,
	allSongs,
	setAllSongs,
	offsetSong,
	setOffsetSong,
}) => {
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [popupShow, setPopupShow] = useState(false);
	const [currentSongId, setCurrentSongId] = useState("");
	const [deleteSong] = useDeleteSongMutation();
	const [deleteAlbum] = useDeleteAlbumMutation();
	const { isAdded } = useSelector((state) => state.albumSlice);
	const { albumChange } = useSelector((state) => state.temp);


	useEffect(() => {
		if (isAdded || albumChange) {
			setAllSongs([]);
			setOffsetSong(0);
			dispatch(setAlbumChange(false));
		}
		setIsLoading(true);
		axios
			.get(`album/get-songs?offset=${offsetSong}&album_id=${createdAlbumId}`)
			.then(({ data }) => {
				if (data.status) {
					setData(data);
					setAllSongs((pre) => [...pre, ...data.data]);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, [offsetSong, isAdded, reloadSongComponent, createdAlbumId]);

	const handelPlusBtn = (id) => {
		if (localStorage.getItem("user_id") === id) {
			setShowAddSongPopup(true);
		} else {
		}
	};

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setOffsetSong((pre) => pre + 10);
		}
	}

	return (
		<div className="albumPopup">
			<button className="closeAlbumBtn" onClick={() => setShowAlbumPopup(false)}>
				<img src={arrowIcon} alt="" />
			</button>

			<div className="innerAlbumPopup" style={{ paddingTop: "0px", display: "flex", flexDirection: "column" }}>
				<div className="albumHeader">
					<div className="albumHBgCont">
						<div className="albumHBg"></div>
						<img src={data?.albumDetails?.cover_photo} alt="cover photo" />
						{localStorage.getItem("user_id") === data?.albumDetails?.created_by?._id && (
							<button
								className="albumDeleteBtn"
								onClick={() => {
									swal({
										title: "You want to delete this album ?",
										buttons: true,
										dangerMode: true,
									}).then((e) => {
										if (e) {
											deleteAlbum(data?.albumDetails?._id);
											setShowAlbumPopup(false);
										}
									});
								}}>
								<RiDeleteBin2Fill size={24} color="#f00" />
							</button>
						)}
					</div>
					<div className="albumHTextCont">
						<div className="textAndPlay">
							<h1>{data?.albumDetails?.title}</h1>
							{localStorage.getItem("user_id") === data?.albumDetails?.created_by?._id ? (
								<div onClick={() => handelPlusBtn(data.albumDetails?.created_by?._id)}>
									<FaPlus color="#fff" size={20} />
								</div>
							) : (
								<div onClick={() => console.log("play")}>
									<img src={paly2} alt="" />
								</div>
							)}
						</div>
						<p className="byAuthor">
							by <span>{data?.albumDetails?.created_by?.full_name}</span>
						</p>
						<p className="albumDec">{data?.albumDetails?.description}</p>
					</div>
				</div>
				<div className="otherAlbumBody" onScroll={ReachBottom}>
					<p className="otherAlbum">Album Songs</p>
					{allSongs?.length === 0 && <h3>No Songs</h3>}
					{allSongs?.map((item, index) => {
						return (
							<div className="albumItemContainer" key={index} onClick={() => dispatch(setCurrentSong(item))}>
								<div className="albumItemContainerLeft">
									<div className="imgAlbumOther">
										<img src={item.thumbnail} alt="thumbnail" />
									</div>
									<div className="aTextB">
										<h3>{item.title}</h3>
										<div>
											<span>Album</span>
											<span>Video</span>
										</div>
									</div>
								</div>
								<div className="albumItemContainerRight">
									{localStorage.getItem("user_id") === item?.album_id?.created_by && (
										<span
											onClick={(e) => {
												e.stopPropagation();
												setPopupShow(!popupShow);
												setCurrentSongId(item._id);
											}}>
											:
										</span>
									)}
									{popupShow && currentSongId === item._id && (
										<div className="AlbumItemPopUp">
											<button
												onClick={(e) => {
													e.stopPropagation();
													setPopupShow(!popupShow);
													swal({
														title: "You want to delete this song ?",
														buttons: true,
														dangerMode: true,
													}).then((e) => {
														if (e) {
															dispatch(setCreated(false));
															deleteSong(item._id);
														}
													});
												}}>
												Delete
											</button>
											{/* <button>Edit</button> */}
										</div>
									)}
								</div>
							</div>
						);
					})}
					{isLoading ? (
						<div
							style={{
								with: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<ColorRing
								visible={true}
								height="50"
								width="50"
								ariaLabel="blocks-loading"
								wrapperClass="blocks-wrapper"
								colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
							/>
						</div>
					) : (
						data?.data?.length === 0 && <h4 style={{ textAlign: "center" }}>No more songs</h4>
					)}
				</div>
			</div>
		</div>
	);
};

export default AlbumPopup;
