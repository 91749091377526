import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import { updateUserData } from "../slices/AuthSlice";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token");
			headers.set("authorization", token);
			return headers;
		},
	}),
	tagTypes: ["User"],
	endpoints: (builder) => ({
		fetchUserDetails: builder.query({
			queryFn: async (id, api) => {
				try {
					const { data } = await axios.get(`auth/get-user-details?user_id=${id}`);
					if (data.status) {
						api.dispatch(updateUserData(data.data.data));
					}
					return data;
				} catch ({ response }) {
					return {
						error: {
							status: response.status,
							message: response.statusText,
						},
					};
				}
			},
			providesTags: ["User"],
		}),
		enrollBtn: builder.mutation({
			queryFn: async (classId) => {
				const formData = new FormData();
				formData.append("user_id", localStorage.getItem("user_id") || sessionStorage.getItem("user_id"));
				try {
					const { data } = await axios.post(`class/enrollCourse/${classId}`, formData);
					if (data.status) {
						toast.success("Enrolled Successfully !!");
					}
					return { data: data };
				} catch ({ response }) {
					return {
						error: {
							status: response.status,
							message: response.statusText,
						},
					};
				}
			},
			invalidatesTags: ["User"],
		}),
	}),
});
export const { useFetchUserDetailsQuery, useEnrollBtnMutation } = authApi;
