import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import useProfessionalValidate from "../../components/Hooks/useProfessionalValidate";
import leftImg2 from "../../images/Rectangle 79.png";
import leftImg1 from "../../images/Rectangle 80 (1).png";
import Header from "../Professional_header";
import CreateClass from "./CreateClass";
import EditClass from "./EditClass";

function MyClassesProfessional() {
	useProfessionalValidate();
	const navigate = useNavigate();
	const [showCreateClass, setShowCreateClass] = useState(false);
	const [showEditClass, setShowEditClass] = useState(false);
	const [classId, setClassId] = useState("");
	const [allClasses, setAllClasses] = useState([]);
	const [reloadUseEffect, setReloadUseEffect] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [ActiveClassId, setActiveClassId] = useState("");
	const [isRightClickPopup, setIsRightClickPopup] = useState(false);
	const classRef = useRef();

	useEffect(() => {
		setIsLoading(true);
		axios
			.get("/class/getclassByUserid/" + localStorage.getItem("user_id") || sessionStorage.getItem("user_id"))
			.then(({ data }) => {
				if (data.status == true) {
					setAllClasses(data.data);
					console.log(data);
					setIsLoading(false);
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log(error));
	}, [reloadUseEffect]);

	useEffect(() => {
		let rightClickPopup = (e) => {
			if (!classRef.current.contains(e.target)) {
				setActiveClassId("");
				setIsRightClickPopup(false);
			}
		};

		document.addEventListener("mousedown", rightClickPopup);
		return () => {
			document.removeEventListener("mousedown", rightClickPopup);
		};
	}, []);

	const handelContextClick = (e) => {
		e.preventDefault();
	};

	const handelRightClick = (e, id) => {
		e.preventDefault();
		setActiveClassId(id);
		setIsRightClickPopup(true);
	};

	const handelDeleteClick = (id) => {
		setIsRightClickPopup(false);
		const tostId = toast.loading("Please wait...");

		axios
			.delete("/class/deleteClass", { data: { _id: id } })
			.then(({ data }) => {
				if (data.status == true) {
					toast.update(tostId, {
						render: "Class Deleted Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
					setReloadUseEffect(Math.random());
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => {
				toast.update(tostId, {
					render: "Something Wrong !!",
					type: "error",
					isLoading: false,
					autoClose: 2000,
				});
				console.log("error", error);
			});
	};

	return (
		<div className="professionalMainContainer">
			<Header />
			{showCreateClass && <CreateClass setShowCreateClass={setShowCreateClass} setReloadUseEffect={setReloadUseEffect} />}
			{showEditClass && (
				<EditClass classId={classId} setShowEditClass={setShowEditClass} setReloadUseEffect={setReloadUseEffect} />
			)}

			<div className="ProfessionalBody learning MyClass">
				<div className="CreateClassButtonContainer">
					<button className="CreateClassButton" onClick={() => setShowCreateClass(true)}>
						Create Class
					</button>
				</div>
				<div className="display-flex justify-arrond">
					<div>
						<img src={leftImg1} alt="" />
					</div>

					<div className="home-right">
						<div>
							<img src={leftImg2} alt="" />
						</div>
					</div>
				</div>

				{/* <div className="home-right-mid">
					<br />
					<h5>Current Classes</h5>
					<div className="display-grid slider-left2right2">
						<div className="home-right-mid-blocks" onClick={() => window.open("/Professional-ClassInfo/qwq2123", "_self")}>
							<h6>How to create Music Notes</h6>
							<p>Write notes on the music staff to create a song. Notes are made of note heads, stems .....</p>
							<span>12:30 Min Remaining</span>
						</div>
						<div
							className="home-right-mid-blocks"
							onClick={() => window.open("/Professional-ClassInfo/13er1fq", "_self")}
							onContextMenu={(e) => handelContextClick(e)}>
							<h6>How to create Music Notes</h6>
							<p>Write notes on the music staff to create a song. Notes are made of note heads, stems .....</p>
							<span>12:30 Min Remaining</span>
						</div>
						<div className="home-right-mid-blocks" onClick={() => window.open("/Professional-ClassInfo/1f1ceqsa", "_self")}>
							<h6>How to create Music Notes</h6>
							<p>Write notes on the music staff to create a song. Notes are made of note heads, stems .....</p>
							<span>12:30 Min Remaining</span>
						</div>
					</div>
				</div> */}

				<div className="home-right-mid">
					<br />
					<h5>My Classes</h5>
					{isLoading ? (
						<ThreeCircles
							height="100"
							width="100"
							color="#4fa94d"
							wrapperStyle={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
							wrapperClass=""
							visible={true}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					) : (
						<div className="MyClassBoxContainer" ref={classRef}>
							{allClasses.length === 0 &&<p>No Classes</p>}
								{allClasses.map((data, index) => {
								if (data.classType !== "live")
									return (
										<div
											key={index}
											className="MyClassBox"
											onContextMenu={(e) => handelRightClick(e, data._id)}
											onClick={() => {
												if (!isRightClickPopup) navigate("/Professional-ClassInfo/" + data._id);
											}}>
											<img src={data.coverpic} alt="" />
											<div className="MyClassBoxInfo">
												<h6>{data.courseTitle}</h6>
												<p>{data.courseDescription}</p>
												<span>
													{"On " +
														data.createdAt.split("T")[0] +
														" at " +
														data.createdAt.split("T")[1].split(".")[0]}
												</span>
											</div>
											{ActiveClassId === data._id && isRightClickPopup && (
												<div className="LectureEditMenu">
													<button
														type="button"
														onClick={() => {
															setShowEditClass(true);
															setIsRightClickPopup(false);
															setClassId(data._id);
															// console.log("Edit Popup Open", data._id);
														}}>
														Edit
													</button>
													<button type="button" onClick={() => handelDeleteClick(data._id)}>
														Delete
													</button>
												</div>
											)}
										</div>
									);
							})}
						</div>
					)}
					<br />
					<br />
				</div>
			</div>
		</div>
	);
}
export default MyClassesProfessional;
