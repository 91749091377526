import React, { useEffect, useRef, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import styles from "../../css/LiveClass.module.scss";
import Header from "../header.component";

const WatchLiveClass = () => {
	const [text, setText] = useState("");
	const videoRef = useRef(null);

	const toggleFullScreen = () => {
		const video = videoRef.current;

		if (video.requestFullscreen) {
			video.requestFullscreen();
		} else if (video.mozRequestFullScreen) {
			/* Firefox */
			video.mozRequestFullScreen();
		} else if (video.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			video.webkitRequestFullscreen();
		} else if (video.msRequestFullscreen) {
			/* IE/Edge */
			video.msRequestFullscreen();
		}
	};

	const handelPostComment = () => {
		console.log(text);
		setText("");
	};

	const { search } = useLocation();
	const PlayBackUrl = search.split("=")[1];

	useEffect(() => {
		if (PlayBackUrl==="") return;
		if (!window.IVSPlayer.isPlayerSupported) {
			alert("Your browser does not support the IVS video player. Please try a different browser.");
		}
		const videoEl = document.getElementById("video-player");
		// const streamUrl =
		// "https://4dba70f4ad89.us-east-1.playback.live-video.net/api/video/v1/us-east-1.825463999154.channel.ItwgnWI6KVjo.m3u8";
		const streamUrl = PlayBackUrl;
		const ivsPlayer = window.IVSPlayer.create();
		ivsPlayer.attachHTMLVideoElement(videoEl);
		ivsPlayer.load(streamUrl);
		ivsPlayer.play();
	}, [PlayBackUrl]);

	return (
		<div className="UserMainContainer">
			{window.innerWidth > 1000 && <Header />}
			<div className={styles.WatchLiveClass}>
				<div className={styles.LeftSection}>
					<div className={styles.Content}>
						<div className={styles.VideoContainer}>
							<video
								ref={videoRef}
								onClick={toggleFullScreen}
								id="video-player"
								// controls
								autoPlay
								muted={false}
							/>

							<div className={styles.VideoTop}>
								<div className={styles.Left}>
									<div className={styles.imageProfile}>
										<img src="https://picsum.photos/200/300" alt="" />
									</div>
									<div className={styles.textContender}>
										<h5>Rohan Mondal</h5>
										<p>@RohanMondal</p>
									</div>
								</div>
								<div className={styles.Right}>
									<div className={styles.BtnContainer}>
										<button>Live</button>
										<button>
											<AiFillEye /> 12
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.RightSection}>
					<div className={styles.Content}>
						<h1>Live Comments</h1>

						<div className={styles.CommentContainer}>
							{Array(20)
								.fill("")
								.map((data, index) => (
									<div className={styles.CommentBox} key={index}>
										<img src="https://picsum.photos/200/300" alt="" />
										<div className={styles.textC}>
											<h6>Rohan Mondal</h6>
											<p>Hi mam , How are you ?</p>
										</div>
									</div>
								))}
						</div>

						<div className={styles.CommentInputContainer}>
							<div className={styles.InputContainer}>
								<input
									type="text"
									placeholder="Type your comment"
									value={text}
									onChange={(e) => setText(e.target.value)}
								/>
							</div>
							<button onClick={handelPostComment}>
								<BsFillSendFill />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WatchLiveClass;
