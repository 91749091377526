import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { logout } from "../Redux/slices/AuthSlice";
import FollowList from "../components/FollowList";
import useProfessionalValidate from "../components/Hooks/useProfessionalValidate";
import ProfilePost from "../components/ProfilePost";
import styles from "../css/ProfessionalAccount.module.scss";
import userDefaultImage from "../images/userDefaultImage.jpg";
import Header from "./Professional_header";
import MessagePopup from "../components/MessagePopup";

function AccountProfessional() {
	useProfessionalValidate();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isMessageOpen } = useSelector((state) => state.temp);

	const handelDeleteAccount = () => {
		swal({
			text: "You want to delete this account ?",
			buttons: true,
			dangerMode: true,
		}).then((val) => {
			if (!val) return;
			console.log("done");

			fetch(
				process.env.REACT_APP_BASE_URL + "/auth/delete-profile/" + localStorage.getItem("user_id") ||
					sessionStorage.getItem("user_id"),
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
				.then((result) => {
					if (result.status == 200) {
						localStorage.removeItem("auth_token");
						localStorage.removeItem("user_type");
						localStorage.removeItem("user_id");
						localStorage.removeItem("remember_me");
						navigate("/", { replace: true });
					} else swal("Something want wrong !! Please try again");
				})
				.catch((error) => console.log("error: ", error));
		});
	};

	const LogoutBtn = () => {
		dispatch(logout());
		navigate("/", { replace: true });
	};

	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [description, setDescription] = useState("");
	const [pic, setPic] = useState("");
	const [courseData, setCourseData] = useState([]);
	const [postData, setPostData] = useState([]);
	const [post, setPost] = useState(true);
	const [album, setAlbum] = useState(false);
	const [page, setPage] = useState(0);
	const [ScrollButton, setScrollButton] = useState(false);
	const [e, setBottom] = useState("");
	const [reload, setReload] = useState(false);
	const [count, setCount] = useState({});
	const [classCount, setClassCount] = useState(0);
	const { userId } = useSelector((state) => state.auth);
	const [followShow, setFollowShow] = useState(false);
	const [followType, setFollowType] = useState("");

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setScrollButton(bottom);
			setPage((prev) => prev + 10);
			console.log(bottom, "reach Bottom", page);
		}
	}

	useEffect(() => {
		if (userId === "") return;
		getData(process.env.REACT_APP_BASE_URL + "/auth/get-user-details?user_id=" + userId, "GET").then((data) => {
			if (data.status == true) {
				setName(data.data.data.full_name);
				setEmail(data.data.data.email);
				setDescription(data.data.data.description);
				setPic(data.data.data.pic);
			} else {
				console("incorrect");
			}
		});

		getData(process.env.REACT_APP_BASE_URL +"/post/get-post?offset=0&type=", "GET").then((data) => {
			if (data.status == true) {
				setPostData(data.data.data);
				setCount(data.data.count);
			} else {
				console.log("incorrect");
			}
		});

		getData(process.env.REACT_APP_BASE_URL +"/class/getclassByUserid/" + userId, "GET").then((data) => {
			if (data.status == true) {
				setCourseData(data.data);
				setClassCount(data.Count);
			} else {
				console.log("incorrect");
			}
		});
	}, [userId]);

	let BtnArray = ["Terms And Conditions", "Support & Help", "Privacy Policy", "About", "Delete Account"];

	function buttons(item, index) {
		return (
			<button
				key={index}
				className="btn btn-outline-secondary accountBtn"
				onClick={() => {
					if (item == "Terms And Conditions") navigate("/Professional-term-conditions");
					if (item == "Digital Visiting Card") navigate("/Professional-account");
					if (item == "My Purchase") navigate("/Professional-coursePurchase");
					if (item == "Support & Help") navigate("/support");
					if (item == "Privacy Policy") navigate("/Professional-privacy");
					if (item == "About") navigate("/Professional-aboutUs");
					if (item == "Delete Account") handelDeleteAccount();
				}}>
				{item}
			</button>
		);
	}

	return (
		<div className="professionalMainContainer">
			<Header />
			{isMessageOpen && <MessagePopup />}
			{followShow && <FollowList setFollowShow={setFollowShow} followType={followType} userId={userId} />}

			<div className="BasicStyle-professional accountContainer ProfessionalBody">
				<div className="account"></div>

				<div className="home-right-mid">
					<div className="display-flex">
						<div className="apc">
							<div
								style={{
									marginTop: "-10%",
									width: "121.2px",
									height: "123.65px",
									overflow: "hidden",
									borderRadius: "50%",
								}}>
								<img src={pic == "" ? userDefaultImage : pic} alt="" style={{ width: "100%", height: "100%" }} />
							</div>
							<button className="apc-button" onClick={() => window.open("/Professional-profile-update", "_self")}>
								Edit ✎{" "}
							</button>
							<h3>{name}</h3>
							<div className="display-flex">
								{/* <button className="TopPlayer">Top Player</button> */}
								<div className="display-flex">
									{/* Profile Rating &nbsp; <p className="text-warning"> 4.8</p> */}
								</div>
							</div>
							<div className={styles.belowTopBtn}>
								<h3>
									{count.post}
									<span>Post</span>
								</h3>
								<h3>
									{classCount}
									<span>Classes</span>
								</h3>
								<h3
									onClick={() => {
										setFollowType("Followers");
										setFollowShow(true);
									}}>
									{count.follow}
									<span>Followers</span>
								</h3>
								<h3
									onClick={() => {
										setFollowType("Following");
										setFollowShow(true);
									}}>
									{count.followiing}
									<span>Following</span>
								</h3>
							</div>
							<h5 className="text-secondary">ABOUT THE ARTIST</h5>
							<p>{description == "" ? "No Description" : description}</p>
							<div className={`display-flex ${styles.buttonContainer}`}>
								{BtnArray.map((item, index) => buttons(item, index))}
							</div>
							<br />
							<br />
							<button className="btn btn-danger" style={{ marginLeft: "40%" }} onClick={LogoutBtn}>
								Log Out
							</button>
						</div>

						<div className="PostImgCon">
							<br />
							<div className="display-flex">
								<div
									style={{ cursor: "pointer" }}
									className={post ? "primary-color" : ""}
									onClick={() => {
										setPost(true);
										setAlbum(false);
									}}>
									Post
								</div>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<div
									style={{ cursor: "pointer" }}
									className={album ? "primary-color" : ""}
									onClick={() => {
										setPost(false);
										setAlbum(true);
									}}>
									Classes
								</div>
							</div>
							<br />
							<div className="postAndAlbumContainer">
								{post && (
									<div
										className="PostContainerImage"
										onScroll={
											((e) => {
												setBottom(e.target.value);
											},
											ReachBottom)
										}>
										{postData.length == 0 ? (
											<p style={{ color: "white", fontSize: "30px" }}>No Post</p>
										) : (
											<ProfilePost page={page} setPage={setPage} reload={reload} setReload={setReload} />
										)}
									</div>
								)}
								{album && (
									<div className={styles.albumContainerImage}>
										{courseData.length == 0 ? (
											<p style={{ color: "white", fontSize: "30px" }}>No Classes</p>
										) : (
											courseData.map((item, index) => (
												<div className={styles.courseItem}>
													<img key={index} src={item.coverpic} alt={item} />
													<div className={styles.details}>
														<h4>{item.courseTitle}</h4>
														<p>{item.courseDescription}</p>
													</div>
												</div>
											))
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AccountProfessional;
