import { motion } from "framer-motion";
import React from "react";
import { RxCrossCircled } from "react-icons/rx";
import styles from "../../css/LiveClassPopup.module.scss";

const SendLinkStudentPopup = ({ setShow }) => {
	return (
		<motion.div
			initial={{ y: "-500px" }}
			animate={{ y: "0px" }}
			className={styles.JoinersPopup}
			onClick={(e) => e.stopPropagation()}>
			<RxCrossCircled className={styles.cross} onClick={() => setShow(false)} />

			<h1>Joiners</h1>
			<h6>5 viewers</h6>

			<div className={styles.CommentContainer}>
				{Array(10)
					.fill("")
					.map((data, index) => (
						<div className={styles.Box} key={index}>
							<div className={styles.ProfileCont}>
								<div className={styles.Img}>
									<img src="https://picsum.photos/200/300" alt="" />
								</div>
								<div className={styles.textT}>
									<h4>Rohan Mondal</h4>
									<p>@RohanMondal</p>
								</div>
							</div>
							<button className={styles.Send}>Send</button>
						</div>
					))}
			</div>
		</motion.div>
	);
};

export default SendLinkStudentPopup;
