import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../Hooks/axios";
import { useSelector } from "react-redux";

const PaymentVerify = () => {
	const { id } = useParams();
    const navigate = useNavigate();

	const token =
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGMwMWMwZDI5ODBjYjIwMmFiMjFhNTMiLCJpYXQiOjE2OTAzNTc2NjMsImV4cCI6MTY5NTU0MTY2M30.jLcZCHY1AkD3QwTPitqHx4Tj0llZ8E9T5gw1c1tQjEI";

	useEffect(() => {
		if (id === "") return;
		axios
			.get(`https://stage.musicbook.co.in/api/v1/order/get-cc-order/${id}`, {
				headers: {
					Authorization: token,
				},
			})
			.then(({ data }) => {
				console.log(data.data[0].cc_avenue_status.order_status);
				if (data.data[0].cc_avenue_status.order_status === "Success") navigate("/payment/successful",{replace:true});
				else navigate("/payment/cancel", { replace: true });
			})
			.catch((e) => {
				console.log(e);
			});
	}, [id]);
};

export default PaymentVerify;
