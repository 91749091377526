import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import useProfessionalValidate from "../../components/Hooks/useProfessionalValidate";
import PDF from "../../components/svgCom/PDF";
import PlayIcon from "../../components/svgCom/PlayIcon";
import InfoIcon from "../../images/InfoIcon.png";
import Start from "../../images/Star 2.png";
import supportIcon from "../../images/supportIcon.png";
import Header from "../Professional_header";
import AddLecture from "./AddLecture";
import EditLecture from "./EditLecture";

const MyClassInfoProfessional = () => {
	useProfessionalValidate();
	const classId = useParams();
	const navigate = useNavigate();

	const [ActiveLectureId, setActiveLectureId] = useState("");
	const [ActiveRightId, setActiveRightId] = useState("");
	const [isRightClickPopup, setIsRightClickPopup] = useState(false);
	const [classData, setClassData] = useState([]);
	const [lecture, setLecture] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [name, setName] = useState("");
	const [infoShow, setInfoShow] = useState(false);
	const [supportShow, setSupportShow] = useState(false);
	const [videoLink, setVideoLink] = useState("");
	const [reloadUseEffect, setReloadUseEffect] = useState("");
	const [addLectureShow, setAddLectureShow] = useState(false);
	const [editLectureShow, setEditLectureShow] = useState(false);
	const [lectureData, setLectureData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [progress, setProgress] = useState(0);
	const [courseFees, setCourseFees] = useState("");
	const lectureRef = useRef();
	const videoRef = useRef(null);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get("/class/getOneClass/" + classId.id)
			.then(({ data }) => {
				if (data.status == true) {
					console.log(data.data);
					setClassData(data.data);
					setLecture(data.data.media);
					setReviews(data.data.review);
					setName(data?.data?.created_by?.full_name);
					setCourseFees(data?.data?.courseFees);
					setIsLoading(false);
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log("error", error));
	}, [reloadUseEffect]);

	useEffect(() => {
		let rightClickPopup = (e) => {
			if (!lectureRef.current.contains(e.target)) {
				setActiveRightId("");
				setIsRightClickPopup(false);
			}
		};

		document.addEventListener("mousedown", rightClickPopup);
		return () => {
			document.removeEventListener("mousedown", rightClickPopup);
		};
	}, []);

	const handelRightClick = (e, id) => {
		e.preventDefault();
		setActiveRightId(id);
		setIsRightClickPopup(true);
	};

	const handelDeleteClick = (id) => {
		setIsRightClickPopup(false);
		setActiveRightId("");
		axios
			.delete("/class/deleteLacture/" + id)
			.then(({ data }) => {
				if (data.status == true) {
					setReloadUseEffect(Math.random());
					toast.success("Lecture Deleted Successfully");
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log("error", error));
	};

	const handelProgress = (e) => {
		let progress = Math.ceil((e.target.currentTime / e.target.duration) * 100);
		setProgress(progress);
		if (progress == 100) {
			setProgress(0);
			setActiveLectureId("");
			setVideoLink("");
		}
	};

	const handelCommentDelete = (id) => {
		axios
			.delete("admin/deleteReviewFromClass", {
				data: {
					class_id: classId.id,
					review_id: id,
				},
			})
			.then(({ data }) => {
				if (data.status == true) {
					console.log(data);
					setReloadUseEffect(Math.random());
					toast.success("Comment Deleted Successfully");
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log("error", error));
	};

	const totalReview = reviews.reduce((currentReview, review) => {
		return currentReview + parseInt(review.rating);
	}, 0);
	const Rating = (totalReview / reviews.length).toFixed(1);

	return (
		<div className="professionalMainContainer ClassInfo">
			<Header />
			<div className="ProfessionalBody ClassInfoContainer">
				{infoShow && (
					<div className="InfoContainer">
						<div className="InfoContainerInner">
							<button type="button" onClick={() => setInfoShow(!infoShow)}>
								X
							</button>
							{classData.courseDescription}
						</div>
					</div>
				)}
				{supportShow && (
					<div className="InfoContainer SupportContainer">
						<div className="InfoContainerInner">
							<button type="button" onClick={() => setSupportShow(!supportShow)}>
								X
							</button>
							Feel free to contact us at{" "}
							<span
								style={{
									color: "rgb(106 70 246)",
									fontSize: "30px",
									fontWeight: 700,
									display: "block",
									margin: "30px 0px",
								}}>
								"support@musicbook.co.in"
							</span>{" "}
							for your queries / concerns.
						</div>
					</div>
				)}
				{addLectureShow && (
					<AddLecture
						setReloadUseEffect={setReloadUseEffect}
						setAddLectureShow={setAddLectureShow}
						classId={classId.id}
					/>
				)}
				{editLectureShow && (
					<EditLecture
						setReloadUseEffect={setReloadUseEffect}
						setEditLectureShow={setEditLectureShow}
						lectureData={lectureData}
					/>
				)}
				<div className="ClassInfoLeft">
					<div className="ClassInfoLeftVideoContainer">
						{videoLink ? (
							<video controls autoPlay src={videoLink} ref={videoRef} onTimeUpdate={handelProgress}></video>
						) : (
							<>
								<div className="ClassInfoLeftVideoContainerOverlay" />
								<img src={classData.coverpic} alt="" />
								<div className="controlsContainer">
									<div className="controlsContainerBottom">
										<div className="h2">
											<h3>{classData.courseTitle}</h3>
											<p>
												By <span>{name}</span>
											</p>
										</div>
										<div className="h2">
											<h3>Course Fee</h3>
											<h4>₹{courseFees}</h4>
										</div>
										<h1 title="Add Lecture" onClick={() => setAddLectureShow(true)}>
											+
										</h1>
									</div>
								</div>
							</>
						)}
					</div>

					<div className="ClassInfoLeftLectureContainer" ref={lectureRef}>
						{isLoading ? (
							<ThreeCircles
								height="100"
								width="100"
								color="#4fa94d"
								wrapperStyle={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
								wrapperClass=""
								visible={true}
								ariaLabel="three-circles-rotating"
								outerCircleColor=""
								innerCircleColor=""
								middleCircleColor=""
							/>
						) : (
							lecture.map((data, i) => {
								const totalMinutes = Math.floor(data.lectureDuration / 60);
								const seconds = data.lectureDuration % 60;
								const hours = Math.floor(totalMinutes / 60);
								const minutes = totalMinutes % 60;
								return (
									<div
										className={`LectureBox ${data._id == ActiveLectureId ? "activeLectureBox" : ""}`}
										key={i}
										onContextMenu={(e) => handelRightClick(e, data._id)}>
										<div className="LectureBoxLeft">
											<h1>{i + 1}</h1>
											<div>
												<h1>{data.lectureTitle}</h1>
												{data._id == ActiveLectureId ? (
													<span>
														<p style={{ width: `${progress}%` }}></p>
													</span>
												) : (
													<p>
														{hours != 0 ? hours + " hrs" : null}{" "}
														{minutes != 0 ? minutes + " min" : null}{" "}
														{seconds != 0 ? seconds + " sec" : null}
													</p>
												)}
											</div>
										</div>
										<div className="LectureBoxRight">
											<button
												onClick={() => {
													if (ActiveLectureId == data._id) {
														setVideoLink("");
														setActiveLectureId("");
													} else {
														setVideoLink(data.videoUpload);
														setActiveLectureId(data._id);
													}
												}}>
												{data._id == ActiveLectureId ? (
													<img src="https://img.icons8.com/ios/50/ffffff/circled-pause.png" />
												) : (
													<PlayIcon />
												)}
											</button>
											<button
												onClick={() => {
													window.location.href = data.documentUpload;
												}}>
												<PDF />
											</button>
										</div>
										{ActiveRightId == data._id && isRightClickPopup && (
											<div className="LectureEditMenu">
												<button
													type="button"
													onClick={() => {
														setIsRightClickPopup(false);
														setEditLectureShow(true);
														setActiveRightId("");
														setLectureData(data);
													}}>
													Edit
												</button>
												<button type="button" onClick={() => handelDeleteClick(data._id)}>
													Delete
												</button>
											</div>
										)}
									</div>
								);
							})
						)}
					</div>
				</div>

				<div className="ClassInfoRight">
					<h1>Courses Resources</h1>
					<div className="RoundBoxContainer">
						<div className="RoundBoxDetail" onClick={() => setInfoShow(!infoShow)}>
							<div className="RoundBox">
								<img src={InfoIcon} alt="" />
							</div>
							<h2>Info</h2>
						</div>
						<div
							className="RoundBoxDetail"
							onClick={() => {
								navigate("/Professional-support");
								setSupportShow(!supportShow);
							}}>
							<div className="RoundBox">
								<img src={supportIcon} alt="" />
							</div>
							<h2>Support</h2>
						</div>
					</div>
					<h1 className="OverallRatingText">
						Overall Rating <img src={Start} alt="" /> {Rating === "NaN" ? "0" : Rating}
					</h1>
					<h1>
						Reviews <span>( {reviews.length} Reviews )</span>
					</h1>
					{isLoading ? (
						<ThreeCircles
							height="100"
							width="100"
							color="#4fa94d"
							wrapperStyle={{ with: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
							wrapperClass=""
							visible={true}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					) : (
						<div className="CommentBoxContainer">
							{reviews.length === 0 && <h1>No Reviews yet</h1>}
							{[...reviews].reverse().map((data, index) => {
								return (
									<div className="commentBox" key={index}>
										<div className="commentBoxLeft">
											<img src={data?.created_by?.pic} alt="" />
											<div className="cmtTextCont">
												<h3 onClick={() => navigate(`/profile/${data?.created_by?._id}`)}>
													{data?.created_by?.full_name}
												</h3>
												<h4>{data.reviewText}</h4>
											</div>
										</div>
										<div className="commentBoxRight">
											<img src={Start} alt="" />
											<p>{data.rating}</p>
											<AiTwotoneDelete
												className="commentDeleteIcon"
												onClick={() => handelCommentDelete(data._id)}
											/>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default MyClassInfoProfessional;
