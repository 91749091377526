import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import useProfessionalValidate from "../components/Hooks/useProfessionalValidate";
import socialMediaImg2 from "../images/Vector (10).png";
import socialMediaImg3 from "../images/Vector (20).png";
import socialMediaImg4 from "../images/Vector (30).png";
import socialMediaImg5 from "../images/Vector (40).png";
import socialMediaImg1 from "../images/Vector.png";
import Header from "./Professional_header";

function PrivacyPolicyProfessional() {
	useProfessionalValidate();
	const navigate = useNavigate();
	let BtnArray = ["Support & Help", "About", "Term And Condition"];

	function buttons(val, i) {
		return (
			<button
				key={i}
				onClick={() => {
					if (val === "Support & Help") navigate("/Professional-support");
					if (val === "About") navigate("/Professional-aboutUs");
					if (val === "Term And Condition") navigate("/Professional-term-conditions");
				}}
				className="btn btn-outline-secondary accountBtn">
				{val}
			</button>
		);
	}

	return (
		<div className="professionalMainContainer">
			{localStorage.getItem("auth_token") && <Header />}

			<h4 style={{ paddingLeft: "10px" }}>
				<span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
					◄
				</span>{" "}
				PRIVACY POLICY
			</h4>

			<div className="ProfessionalBody">
				<div className="TextContent">
					<>
						<p>
							At Music book, we are committed to protecting the privacy of our users. This Privacy Policy outlines
							our practices regarding the collection, use, and disclosure of personal information in connection with
							our services.
						</p>
						<p>
							Music book operates a mobile application and website (together referred to as "Platform"). The Music
							book Platform provides training and coaching services in creative fields, such as music, dance,
							painting, and sculpting, offered by instructors or trainers (collectively referred to as "Teachers").
						</p>
						<p>
							Our platform is mainly intended for teachers who are registered on it, as well as for end-users who
							wish to receive training or tutoring from these teachers. We refer to both teachers and end-users as
							"users" or "you". If a user is under the age of 18, we assume that they have received parental consent
							to use our platform.
						</p>
						<p>
							We collect certain information from our users with their prior consent. Any capitalized term used but
							not defined in this Privacy Policy will have the same meaning as defined in our Terms.
						</p>
						<p>
							The privacy of our users is of utmost importance to us. We understand their concerns and, as a
							commitment to providing a safe and secure experience on our platform, we have developed this privacy
							policy to assist users in making informed decisions.
						</p>
					</>
					<br />

					<ul>
						<h5>
							<li>Legal Rights</li>
						</h5>
						<>
							<p>
								The purpose of this Privacy Policy is to provide users with an understanding of what information
								we collect, why we collect it, what information we share, and how users can manage their
								information. Our Privacy Policy is compliant with the Information Technology Act, 2000, the
								Information Technology (Reasonable Security Practices and Procedures and Personal Information)
								Rules, 2011 (IT Rules), and the Information Technology (Intermediaries Guidelines) Rules, 2011.
							</p>
							<p>
								This Privacy Policy and our Terms are governed by Indian laws. Any disputes will be resolved
								through arbitration by a sole arbitrator in accordance with the Arbitration and Conciliation Act,
								1996.
							</p>
						</>
						<br />

						<h5>
							<li>Consent</li>
						</h5>

						<>
							<p>
								You have the option to access the Platform without providing personal information about yourself.
								However, if you interact with us through the Platform, submit information to us, or sign up for
								any promotions or Services, you agree to our collection, use, disclosure, and sharing of your data
								with us, our authorized service providers, and relevant third parties as described in this Privacy
								Policy.
							</p>
							<p>
								By accepting this Privacy Policy, you acknowledge that you have read, understood, and agreed to
								its terms. You consent to the collection, use, and disclosure of your personal information as
								outlined in this policy. We may update this Policy periodically to reflect changes in technology,
								industry practices, and regulatory requirements. We anticipate that most changes will be minor.
								However, if there are any material changes to the policy, we will provide a prominent notice on
								the home page of the website or another conspicuous place on the Platform prior to the change
								taking effect.
							</p>
							<p>
								If you are using the Services on behalf of another individual or entity, such as a child or
								parent, you represent that you are authorized to accept this Privacy Policy and share any
								necessary data on their behalf.
							</p>
						</>
						<br />

						<h5>
							<li>Information Collected</li>
						</h5>

						<>
							<p>
								The Company collects information from Users on the Platform with their consent. However, publicly
								available information can be used, collected, and disclosed without the Users' consent. When
								registering on the Platform or using specific services, Users may provide the Company with
								voluntarily submitted information, including sensitive personal information defined under the IT
								rules. Users agree to allow the Company to collect such information. The Company collects various
								types of information, including name, contact details, date of birth, nationality, age, gender,
								financial information, usage details, IP address, device ID and type, browser type and language,
								operating system, and information collected through cookies and other tracking technologies. The
								Company may also collect information associated with any other account that Users use to sign in
								to the Platform.
							</p>
							<p>
								If Users email the Company, copies of such communication may be stored, including personally
								identifiable information and IP address. The usage information may be collected using cookies,
								which can be disabled or controlled through browser settings. If the Company suspects that any
								provided information is misleading, inaccurate, obsolete or incomplete, it reserves the right to
								refuse the provision of services to the User at its discretion.
							</p>
						</>
						<br />

						<h5>
							<li>How We Use Your Collected Information</li>
						</h5>
						<p>
							Music book has the right to gather and utilize personal information provided by users for the
							following reasons:
						</p>

						<ul>
							<li>
								To enhance customer service: The information collected helps the Resource to quickly respond to
								support requests and improve their assistance.
							</li>
							<li>
								To personalize the user experience: The Resource may analyze information in an aggregate manner to
								understand how its users utilize the resources and services provided on the Site.
							</li>
							<li>
								To improve the Site: Feedback provided by users can be used to enhance the quality of the products
								and services offered by the Resource.
							</li>
							<li>
								To conduct promotional activities, contests, surveys, or other Site features: Users will receive
								information about topics that they have agreed to receive, and the Resource will conduct
								promotional activities accordingly.
							</li>
							<li>
								To send periodic emails: Users may receive updates and information about their orders via email.
								They may also receive emails about company news, product updates, or related information. Users
								who wish to unsubscribe from future emails may do so by following the instructions provided in the
								email or by contacting the Resource through the Site.
							</li>
						</ul>
						<br />

						<h5>
							<li>Disclosure of Personal Information</li>
						</h5>

						<p>
							We may share your personal information with third-party service providers who assist us with our
							services, such as payment processors and marketing partners. We may also disclose your information in
							response to legal requests or to protect our rights or the rights of others.
						</p>
						<br />

						<h5>
							<li>Security of Personal Information:</li>
						</h5>

						<p>
							We take reasonable measures to protect your personal information from unauthorized access or
							disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.
							We implement proper methods of collecting, storing, and processing data, as well as security measures
							to safeguard against any unauthorized access, alteration, disclosure, or destruction of personal
							information, usernames, passwords, transaction details, and other data stored on our Site.
						</p>
						<br />

						<h5>
							<li>Retention of Personal Information</li>
						</h5>

						<p>
							We will retain your personal information for as long as necessary to provide you with our services and
							comply with legal obligations.
						</p>
						<br />

						<h5>
							<li>Musical Classes</li>
						</h5>

						<p>
							In our musical classes, instructors provide personalized instruction to students through our
							application. During these classes, instructors may collect personal information, such as the student's
							name, performance data, and feedback, to tailor the instruction to the student's needs. This
							information is solely used for the purpose of improving the instruction and is not shared or sold to
							third parties without the user's consent. Additionally, we may use this information to improve our
							services and personalize the user experience. We adopt appropriate security measures and use
							encryption to protect against unauthorized access, alteration, disclosure, or destruction of any user
							information. By using our musical application, students agree to our privacy policy and terms of
							service.
						</p>
						<br />

						<h5>
							<li>Practice Recordings</li>
						</h5>

						<p>
							Music book strongly emphasizes the importance of consistent practice in the pursuit of musical
							excellence. To facilitate this, we provide the facility to Practice Recordings which features Practice
							Pages. These pages include video/audio, lyrics/notations, and a recorder, enabling students to listen,
							practice, and record their singing until they master it. While students are welcome to record as many
							practice sessions as they need, there are limitations on the number of recordings that can be
							submitted for feedback, depending on the course. In self-study courses, students may submit one
							practice recording per song.
						</p>
						<br />

						<h5>
							<li>Device Access Policy</li>
						</h5>

						<p>
							Music book strongly emphasizes the importance of consistent practice in the pursuit of musical
							excellence. To facilitate this, we provide the facility to Practice Recordings which features Practice
							Pages. These pages include video/audio, lyrics/notations, and a recorder, enabling students to listen,
							practice, and record their singing until they master it. While students are welcome to record as many
							practice sessions as they need, there are limitations on the number of recordings that can be
							submitted for feedback, depending on the course. In self-study courses, students may submit one
							practice recording per song.
						</p>
						<br />

						<h5>
							<li>Device Access Policy</li>
						</h5>

						<p>
							At the time of enrollment or during the course, students have the flexibility to access their learning
							materials using alternative devices, such as iPhones, iPads, or supported Android devices, through the
							Music book app. This device access enables students to access Courses, Virtual Class Recordings,
							Assignments, and Practice Recordings. The device access, however, will expire 30 days after the last
							class of the course. To download the app, students can go to the Apple Store or Play Store. However,
							to access the content via the app, students need to have a student login ID and password.
						</p>
						<br />

						<h5>
							<li>Access To Learning Materials & Continuation</li>
						</h5>

						<p>
							{`For Instructor-Led courses, students can access course materials for an unlimited time, provided they
							remain enrolled in any Instructor-Led course offered by the Music book. After a course expires,
							students can continue to access course materials by purchasing continued access or enrolling in the
							next course. Course materials comprise any associated Study Plan, assessments, and practice
							recordings. However, if a student takes a break from the program, access to course materials will
							expire 30 days after the last scheduled class of the current batch. If a student wishes to access
							course materials 30 days after the last attended course, they may purchase extended access for six
							months or one year. To purchase extended access, students need to log in to their account, visit your
							Courses and select the relevant course, and click on "Purchase -> Extend Subscription." Self-study
							courses, on the other hand, have access based on the subscription period.`}
						</p>
						<br />

						<h5>
							<li>Courses & Certifications</li>
						</h5>

						<p>
							Music book provides certificate programs for Musical Courses offered by instructors. The musical
							certificate programs have a pre-defined and structured curriculum. For courses other than
							beginner-level courses, students must take a pre-requisite assessment, which is graded by the
							instructors. Based on the student's performance on the assessment and their singing capability, they
							are advised to join a particular level. Once a student completes a course within a level with a
							passing grade, they can progress to the next course.
						</p>
						<br />

						<h5>
							<li>Payments and Refunds</li>
						</h5>

						<p>
							After enrolling in a course, it is not possible to transfer the course to another person. All
							purchased courses must be initiated within 90 days of the selected batch/slot date; otherwise, the
							purchase will be forfeited, and the entire fee will be non-refundable. If a refund is requested, it
							must be done in writing to support@musicbook.co.in within 24 hours of the first scheduled class. Music
							book retains the discretion to grant refunds, and the amount refunded will be based on a schedule that
							starts from the date we receive the written refund request.
						</p>
						<p>
							The registration fee cannot be returned, but if the student decides to take a course in the future,
							the registration fee will be waived. If a refund is requested 5 working days after enrollment, any
							applicable taxes (GST, etc) will not be refunded. Re-enrollment fees cannot be refunded, and the
							course start date can only be postponed once, within 4 weeks from the originally selected start date.
						</p>
						<br />

						<h5>
							<li>Escrow Details</li>
						</h5>

						<p>
							In the context of Music book, the escrow system ensures that both the teachers and the students are
							protected throughout the payment process. When a student purchases a course or lesson from a teacher,
							the payment is held in escrow until the lesson is completed to the satisfaction of the student. This
							ensures that the teacher is paid for their services and the student receives the expected instruction.
							If there are any disputes between the teacher and the student, the escrow system can help to mediate
							the situation and facilitate a resolution. As a part of Music book's Privacy Policy, the platform acts
							as an escrow service in case an instructor fails to attend a scheduled class or course. In such cases,
							Music book will provide a timeline window for the instructors to wait and take the class before
							releasing the payment to them. This ensures that both the students and instructors are protected and
							that payments are only made for completed classes or courses. Music book values the trust of its users
							and strives to maintain a safe and secure environment for all music enthusiasts. At Music book, we
							take the security and protection of our users seriously, and the escrow system is just one of the ways
							we strive to provide a safe and trustworthy platform for learning and teaching music.{" "}
						</p>
						<br />

						<h5>
							<li>Transfers of Data</li>
						</h5>

						<p>
							We are dedicated to adhering to the relevant Principles of each Privacy Shield Framework with regard
							to the personal data we receive from Indian members, respectively. Under each Privacy Framework, Music
							book is responsible for processing any personal data it receives and later passes to a third party
							working as its agent. Music book complies with the Privacy Principles, including the onward transfer
							responsibility requirements, for any transfers of personal data.{" "}
						</p>
						<p>
							We can occasionally be forced to provide personal information in response to valid demands from public
							authorities, including where doing so is necessary for reasons of national security or law
							enforcement.
						</p>
						<br />

						<h5>
							<li>Third-Party Disclaimer</li>
						</h5>

						<p>
							The Platform might have links to other websites, and users need to be aware that when they click on
							such links, they will be directed to a different website that is not under our control. Therefore, we
							will not take responsibility for any consequences that may arise from the use of such third-party
							websites. These websites may require the users to provide personal information, and we encourage them
							to read their privacy policies as they may differ from our Privacy Policy. It is agreed that we shall
							not be held responsible for any privacy breaches or losses incurred by users while using these
							third-party websites. The inclusion or exclusion of any website or content does not imply an
							endorsement by the Company. Users are allowed to visit third-party websites at their own risk and are
							advised to read their privacy policies.
						</p>
						<p>
							Moreover, the Platform may allow users to generate content that can be accessed by other users,
							including moderators or administrators. These users are not representatives of the Company, and their
							opinions or statements do not necessarily reflect the Company's views or opinions. We explicitly
							disclaim any responsibility for the use or misuse of such information made available by the users.
						</p>
						<br />

						<h5>
							<li>Technical Requirements</li>
						</h5>

						<p>
							Before enrolling in courses at Music book, prospective students should carefully review the technical
							requirements. Additionally, our support team will reach out to the students to conduct a test class,
							ensuring that the student meets all technical requirements and is well-equipped to join the class. It
							is important to note that technical issues will not be considered a valid reason for withdrawal or
							refund. Once enrolled, students will receive instructions on how to test their computer setup, webcam,
							and microphone, which should be completed in the week prior to the course's start to allow for
							adequate time to address any issues. If students encounter any technical problems or have questions,
							they can contact support@musicbook.co.in for assistance.
						</p>
						<br />

						<h5>
							<li>Changes To The Privacy Policy</li>
						</h5>

						<p>
							Music book reserves the right to modify this privacy policy at its discretion and at any time. If we
							make any changes, we will indicate the updated date at the bottom of this page. It is recommended that
							users regularly check this page to remain informed about how we protect the personal information we
							collect. You acknowledge and agree that it is your responsibility to periodically review this privacy
							policy and become aware of any modifications.
						</p>
						<br />

						<h5>
							<li>Your Acceptance Of This Terms</li>
						</h5>

						<p>
							By using this Site, you accept and agree to be bound by this policy. If you do not agree to this
							policy, please refrain from using our Site. Your continued use of the Site after we post any changes
							to this policy will indicate your acceptance of those changes.
						</p>
						<br />

						<h5>
							<li>GET IN TOUCH</li>
						</h5>

						<p>
							Please get in touch with us at support@musicbook.co.in if you have any inquiries or suggestions, or
							would like to exercise any of your statutory rights.
						</p>
						<br />
					</ul>

					<h6 className="text-secondary">Follow Us</h6>
					<hr />
					<div className="display-flex socialImages" style={{ paddingBottom: "10px" }}>
						<img
							src={socialMediaImg1}
							alt="Instagram"
							onClick={() => window.open("https://www.instagram.com/musicbook.co.in", "_blank")}
						/>
						<img src={socialMediaImg2} alt="Linkedin" onClick={() => window.open("", "_blank")} />
						<img
							src={socialMediaImg3}
							alt="Facebook"
							onClick={() => window.open("https://www.facebook.com/profile.php?id=100091842444276", "_blank")}
						/>
						<img src={socialMediaImg4} alt="Twitter" onClick={() => window.open("", "_blank")} />
						<img
							src={socialMediaImg5}
							alt="Youtube"
							onClick={() => window.open("https://www.youtube.com/@musicbook7881", "_blank")}
						/>
					</div>

					{localStorage.getItem("auth_token") && <div className="display-flex">{BtnArray.map(buttons)}</div>}
				</div>
			</div>
		</div>
	);
}
export default PrivacyPolicyProfessional;
