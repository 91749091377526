import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	uploadProgress: 0,
	currentSelectedSong: null,
	isAdded: false,
	allSongs: [],
	offsetAlbum: 0,
	offsetTopAlbum: 0,
};

const albumSlice = createSlice({
	name: "albumSlice",
	initialState,
	reducers: {
		setUploadProgress: (state, action) => {
			return {
				...state,
				uploadProgress: action.payload,
			};
		},
		setRemoveAll: (state, action) => {
			state.uploadProgress = 0;
			state.currentSelectedSong = null;
		},
		setCurrentSong: (state, { payload }) => {
			state.currentSelectedSong = payload;
		},
		setCreated: (state, { payload }) => {
			state.isAdded = payload;
		},
	},
});

export const { setUploadProgress, setCurrentSong, setRemoveAll, setCreated, nextOffsetTopAlbum, prevOffsetTopAlbum } =
	albumSlice.actions;

export default albumSlice.reducer;
