import { motion } from "framer-motion";
import React from "react";
import { RxCrossCircled } from "react-icons/rx";
import styles from "../../css/LiveClassPopup.module.scss";
import { useNavigate } from "react-router-dom";

const EndLivePopup = ({ setShow }) => {
	const navigate = useNavigate()

	return (
		<div onClick={() => setShow(false)} className={styles.PopupBackground}>
			<motion.div
				initial={{ y: "-100px" }}
				animate={{ y: "0px" }}
				className={styles.EndLivePopup}
				onClick={(e) => e.stopPropagation()}>
				<RxCrossCircled className={styles.cross} onClick={() => setShow(false)} />
				<h1>Your Live lecture is Ended </h1>
				<div className={styles.VideoContainer}>
					{/* <video src=""></video> */}
					<img src="https://picsum.photos/200/300" alt="" />
				</div>
				<p>Post your music lecture </p>
				<h6>Not Now</h6>
				<button onClick={() => navigate("/Professional-LiveClass")}>Post</button>
				<h4>Skip</h4>
			</motion.div>
		</div>
	);
};

export default EndLivePopup;
