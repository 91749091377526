import React, { useRef, useState } from "react";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import swal from "sweetalert";
import { followFunction, setIsFollow } from "../Redux/slices/TempSlice";
import EditPostPopup from "../User/EditPostPopup";
import VectorCommentIcon from "../images/VectorCommentIcon.svg";
import VectorShare from "../images/VectorShare.svg";
import Comment from "./Comment";
import { ProfileImagePopup } from "./ProfileImagePopup";
import { RatingBtn } from "./RatingBtn";
import ReportPopup from "./ReportPopup";
import ThreeDotPopup from "./ThreeDotPopup";
import ThreeDotPopupForOtherUser from "./ThreeDotPopupForOtherUser";
// import share from "../images/share.png";
// import { LikeBtn } from "./LikeBtn";

const VideoPost = ({
	val,
	visible,
	Visible,
	post,
	show,
	setShow,
	postId,
	setPostId,
	imgUrl,
	setReload,
	setPage,
	otherUserPopup,
	setOtherUserPopup,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const [caption, setCaption] = useState(val.captions);
	const [commentCount, setCommentCount] = useState(val.comment_counts);
	const [EditPostShow, setEditPostShow] = useState(false);
	const [ShowProfilePic, setShowProfilePic] = useState(false);
	const [ReadMore, setReadMore] = useState(false);
	const [reportPopupShow, setReportPopupShow] = useState(false);
	const [PostRating, setPostRating] = useState(val.rating);
	const videoRef = useRef(null);
	const [profileRating, setProfileRating] = useState(val?.created_by?.rating);
	const [followState, setFollowState] = useState(val.is_follow);

	// const profileRating = val.created_by.rating;

	const handleBlur = () => {
		videoRef.current.pause();
	};

	return (
		<>
			{postId == val._id && EditPostShow && (
				<EditPostPopup setEditPostShow={setEditPostShow} val={val} setCaption={setCaption} />
			)}
			{postId == val._id && reportPopupShow && (
				<ReportPopup setReportPopupShow={setReportPopupShow} postId={postId} userId={val?.created_by?._id} />
			)}

			<div className="PostMainContainer">
				<center className="post-img-bg">
					<video onBlur={handleBlur} ref={videoRef} controls controlsList="nodownload">
						<source src={imgUrl} type="video/mp4" />
						<source src={imgUrl} type="video/webm" />
						<source src={imgUrl} type="video/wmv" />
					</video>
				</center>

				<div className="ProfileCaptionContainer vtc">
					{ReadMore ? (
						<>
							{caption}
							<button type="button" onClick={() => setReadMore(false)}>
								{" "}
								...Read Less
							</button>
						</>
					) : (
						<>
							{caption.slice(0, 50)}
							{caption.length > 50 ? (
								<button type="button" onClick={() => setReadMore(true)}>
									{" "}
									... Read More
								</button>
							) : (
								""
							)}
						</>
					)}
				</div>

				<div className="PostProfileContainer">
					{postId == val._id && ShowProfilePic && (
						<ProfileImagePopup ProfileImage={val?.created_by?.pic} setShowProfilePic={setShowProfilePic} />
					)}
					<div className="PostProfileContainerLeft">
						<div
							className="PostProfileImageContainer"
							onClick={() => {
								setPostId(val._id);
								setShowProfilePic(!ShowProfilePic);
							}}>
							<div className="circImg">
								<img src={val?.created_by?.pic} alt="" />
							</div>

							<div className="circ">
								<div className="sect"></div>
								<div className="sect" style={{ borderRightColor: profileRating >= 4 && "rgba(255, 168, 0, 1)" }}>
									4
								</div>
								<div className="sect"></div>
								<div className="sect" style={{ borderRightColor: profileRating >= 3 && "rgba(255, 168, 0, 1)" }}>
									3
								</div>
								<div className="sect"></div>
								<div className="sect" style={{ borderRightColor: profileRating >= 2 && "rgba(255, 168, 0, 1)" }}>
									2
								</div>
								<div className="sect"></div>
								<div className="sect" style={{ borderRightColor: profileRating >= 1 && "rgba(255, 168, 0, 1)" }}>
									1
								</div>
								<div className="sect"></div>
								<div className="sect" style={{ borderRightColor: profileRating === 5 && "rgba(255, 168, 0, 1)" }}>
									5
								</div>
							</div>
						</div>
						<div className="PostProfileStarTextContainer">
							<p
								className="PostProfileText"
								onClick={() => {
									if (
										localStorage.getItem("user_id") === val?.created_by?._id ||
										sessionStorage.getItem("user_id") === val?.created_by?._id
									)
										navigate("/account");
									else {
										// dispatch(setIsFollow(val.is_follow));
										dispatch(setIsFollow(followState));
										navigate(`/profile/${val?.created_by?._id}`);
									}
								}}>
								{val?.created_by?.full_name}
							</p>
							<div className="PostProfileStarContainer">
								{Array.from({ length: 5 }, (_, index) => {
									let starHalf = index + 0.5;
									return PostRating >= index + 1 ? (
										<FaStar key={index} color={"#FFA800"} />
									) : PostRating >= starHalf ? (
										<FaStarHalfAlt key={index} color={"#FFA800"} />
									) : (
										<FaRegStar key={index} color={"#FFA800"} />
									);
								})}
								{user._id === val?.created_by?._id ? (
									""
								) : (
									<p
										onClick={() => {
											if (followState) {
												swal({
													text: "You want to unFollow this user ?",
													buttons: true,
													dangerMode: true,
												}).then((confirmed) => {
													if (confirmed) {
														dispatch(followFunction({ user: user, profile: val?.created_by }));
														setFollowState(false);
													}
												});
											} else {
												dispatch(followFunction({ user: user, profile: val?.created_by }));
												setFollowState(true);
											}
										}}>
										{followState ? "Following" : "Follow"}
									</p>
								)}
							</div>
						</div>
					</div>

					<div className="PostProfileContainerRight">
						<RatingBtn
							postUserId={val?.created_by?._id}
							postId={val._id}
							totalRating={val.rating_data[0]?.totalrating}
							setPostRating={setPostRating}
							is_rated={val.is_rated}
							rated_count={val.rated_count}
							setProfileRating={setProfileRating}
						/>

						<div className="PostComment" onClick={Visible}>
							<img src={VectorCommentIcon} alt="VectorCommentIcon" />
							<p>{commentCount}</p>
						</div>

						<div className="PostDotShare">
							{localStorage.getItem("user_id") == val?.created_by?._id ? (
								<p
									onClick={() => {
										setShow(!show);
										setOtherUserPopup(false);
										setPostId(val._id);
									}}>
									⋮
								</p>
							) : (
								<RWebShare
									data={{
										text: "MusicBook",
										url: "/share/" + val._id,
										title: "MusicBook",
									}}
									onClick={() => console.log("shared successfully!")}>
									<img src={VectorShare} alt="" />
								</RWebShare>
							)}
						</div>

						{localStorage.getItem("user_id") !== val?.created_by?._id && (
							<div
								className="ReportPostContainer"
								onClick={() => {
									setPostId(val._id);
									setShow(false);
									setOtherUserPopup(!otherUserPopup);
								}}>
								⋮
							</div>
						)}

						{postId === val._id && otherUserPopup && (
							<ThreeDotPopupForOtherUser
								setOtherUserPopup={setOtherUserPopup}
								val={val}
								reportPopupShow={reportPopupShow}
								setReportPopupShow={setReportPopupShow}
							/>
						)}

						{postId == val._id && show && (
							<ThreeDotPopup
								setShow={setShow}
								val={val}
								setEditPostShow={setEditPostShow}
								setReload={setReload}
								setPage={setPage}
							/>
						)}
					</div>
				</div>

				{postId == val._id ? <Comment visible={visible} val={val} setCommentCount={setCommentCount} /> : null}
			</div>
		</>
	);

	// return (
	// 	<center className="mt-5 post">
	// 		<center className="post-img-bg">
	// 			<video
	// 				className="video"
	// 				width="520"
	// 				height="220"
	// 				onBlur={handleBlur}
	// 				ref={videoRef}
	// 				controls
	// 				controlsList="nodownload">
	// 				<source src={imgUrl} type="video/mp4" />
	// 				<source src={imgUrl} type="video/webm" />
	// 				<source src={imgUrl} type="video/wmv" />
	// 			</video>
	// 		</center>
	// 		<center>
	// 			<div className="like-comment-bar">
	// 				<p className="like-comment-bar-caption">{caption}</p>
	// 				<div className="justify-space">
	// 					<div className="display-flex userName">
	// 						<div className="postProfileImageContainer">
	// 							<img style={{ paddingBottom: "0%" }} src={val.created_by.pic} alt="" />{" "}
	// 						</div>
	// 						&nbsp;&nbsp;&nbsp;&nbsp;
	// 						<div>
	// 							<p
	// 								className="like-comment-bar-name"
	// 								style={{ cursor: "pointer" }}
	// 								onClick={() => {
	// 									if (localStorage.getItem("user_id") == val.created_by._id)
	// 										window.open("/account", "_self");
	// 									else window.open("/profile/" + val.created_by._id, "_self");
	// 								}}>
	// 								{val.created_by.full_name}
	// 							</p>
	// 						</div>
	// 					</div>
	// 					<div className="display-flex">
	// 						<div className="display-flex">
	// 							<LikeBtn posts={post} postId={val._id} likes_count={val.likes_count} is_like={val.is_like} />
	// 						</div>{" "}
	// 						&nbsp;&nbsp;&nbsp;&nbsp;
	// 						<div className="display-flex">
	// 							<h5 className="comment-png" onClick={Visible} style={{ cursor: "pointer" }}>
	// 								💬
	// 							</h5>
	// 							<p>{commentCount}</p>
	// 						</div>
	// 						&nbsp;
	// 						<div style={{ cursor: "pointer" }}>
	// 							{localStorage.getItem("user_id") == val.created_by._id ? (
	// 								<div
	// 									onClick={() => {
	// 										setShow(true);
	// 										setPostId(val._id);
	// 									}}>
	// 									&nbsp;&nbsp;&nbsp;⋮
	// 								</div>
	// 							) : (
	// 								<>
	// 									<RWebShare
	// 										data={{
	// 											text: "MusicBook",
	// 											url: "/share/" + val._id,
	// 											title: "MusicBook",
	// 										}}
	// 										onClick={() => console.log("shared successfully!")}>
	// 										<img src={share} alt="" className="share-img" />
	// 									</RWebShare>
	// 								</>
	// 							)}
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</center>

	// 		{postId == val._id ? (
	// 			<>
	// 				{show && (
	// 					<ThreeDotPopup
	// 						setShow={setShow}
	// 						val={val}
	// 						setEditPostShow={setEditPostShow}
	// 						setReload={setReload}
	// 						setPage={setPage}
	// 					/>
	// 				)}
	// 				{EditPostShow && <EditPostPopup setEditPostShow={setEditPostShow} val={val} setCaption={setCaption} />}
	// 			</>
	// 		) : (
	// 			<></>
	// 		)}

	// 		{postId == val._id ? (
	// 			<>
	// 				<Comment visible={visible} val={val} setCommentCount={setCommentCount} />
	// 			</>
	// 		) : (
	// 			<></>
	// 		)}
	// 	</center>
	// );
};

export default VideoPost;
