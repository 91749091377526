import axios from "./axios";

export const createNotification = (id,title,message) => {
    const data = {
		user_id: id,
		title,
		message,
    };
	axios
		.post("notificationUser/notifications/", data)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
};
