import React from "react";

const PDF = () => {
	return (
		<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M32.25 32.25V37.625H10.75V32.25H8.0625V37.625C8.0625 38.3378 8.34565 39.0213 8.84965 39.5253C9.35365 40.0294 10.0372 40.3125 10.75 40.3125H32.25C32.9628 40.3125 33.6463 40.0294 34.1503 39.5253C34.6544 39.0213 34.9375 38.3378 34.9375 37.625V32.25H32.25Z"
				fill="#ECECEC"
			/>
			<path
				d="M28.2188 28.2188L26.3187 26.3187L22.8438 29.7936V18.8125H20.1562V29.7936L16.6813 26.3187L14.7812 28.2188L21.5 34.9375L28.2188 28.2188ZM37.625 5.375V2.6875H29.5625V16.125H32.25V10.75H36.2812V8.0625H32.25V5.375H37.625ZM22.8438 16.125H17.4688V2.6875H22.8438C23.9126 2.68857 24.9373 3.11363 25.6931 3.8694C26.4489 4.62518 26.8739 5.64992 26.875 6.71875V12.0938C26.8739 13.1626 26.4489 14.1873 25.6931 14.9431C24.9373 15.6989 23.9126 16.1239 22.8438 16.125ZM20.1562 13.4375H22.8438C23.2 13.4371 23.5416 13.2955 23.7935 13.0435C24.0455 12.7916 24.1871 12.45 24.1875 12.0938V6.71875C24.1871 6.36247 24.0455 6.02089 23.7935 5.76897C23.5416 5.51704 23.2 5.37536 22.8438 5.375H20.1562V13.4375ZM12.0938 2.6875H5.375V16.125H8.0625V12.0938H12.0938C12.8062 12.0927 13.4891 11.8092 13.9929 11.3054C14.4967 10.8016 14.7802 10.1187 14.7812 9.40625V5.375C14.7805 4.66245 14.4972 3.97929 13.9933 3.47544C13.4895 2.97159 12.8063 2.68821 12.0938 2.6875ZM8.0625 9.40625V5.375H12.0938L12.0951 9.40625H8.0625Z"
				fill="#ECECEC"
			/>
		</svg>
	);
};

export default PDF;
