import React from "react";
import { useParams } from "react-router-dom";
import styles from "../../css/Payment.module.scss";

const PaymentCancel = () => {
	const { id } = useParams();

	return (
		<div className={styles.PaymentCancel}>
			<div className={styles.Container}>
				<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
					<path
						d="M50 65C51.4167 65 52.605 64.52 53.565 63.56C54.525 62.6 55.0033 61.4133 55 60C55 58.5833 54.52 57.395 53.56 56.435C52.6 55.475 51.4133 54.9967 50 55C48.5833 55 47.395 55.48 46.435 56.44C45.475 57.4 44.9967 58.5867 45 60C45 61.4167 45.48 62.605 46.44 63.565C47.4 64.525 48.5867 65.0033 50 65ZM45 45H55V15H45V45ZM0 100V10C0 7.25001 0.98 4.89501 2.94 2.93501C4.9 0.975008 7.25333 -0.00332484 10 8.48897e-06H90C92.75 8.48897e-06 95.105 0.980009 97.065 2.94001C99.025 4.90001 100.003 7.25334 100 10V70C100 72.75 99.02 75.105 97.06 77.065C95.1 79.025 92.7467 80.0033 90 80H20L0 100Z"
						fill="#FF0000"
					/>
				</svg>

				<h1>Sorry , payment Failed </h1>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc augue sagittis, ligula ligula mauris diam. Nam
					dolor adipiscing egestas sed amet. Adipiscing nulla massa vel cursus tortor tincidunt sit risus. Bibendum in
					feugiat cursus
				</p>
				<button>Try again</button>
			</div>
		</div>
	);
};

export default PaymentCancel;
