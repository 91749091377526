import "bootstrap/dist/css/bootstrap.min.css";

import { useState } from "react";
import accountImg from "../images/account.png";
import Withdraw from "./withdrawal.component";

function Wallet() {
	const [show, setShow] = useState(false);

	function visible() {
		setShow(true);
	}

	return (
		<div className="black-bg">
			<Withdraw show={show} onClose={() => setShow(false)} />
			{/* <Header /> */}

			<div className="BasicStyle-professional black-bg">
				<div className="about">
					<h5>◄ WALLET</h5>
				</div>

				<div className="display-flex">
					<div className="balance">
						<p>BALANCE</p>
						<h1>Rs. 5,00,000</h1>
						<br />
						<br />

						<div className="display-flex">
							<button className="supportSubmitBtn" onClick={visible}>
								Withdrawl
							</button>
							<button className="accountBtn">Add Account</button>
						</div>
					</div>

					<div className="acc">
						<img src={accountImg} alt="" />
						<h1>IDFC BANK</h1>
						<h4>2888 8908 9075 0950</h4>
						<br />
						<br />
						<p>Remove bank</p>
					</div>
				</div>

				<br />

				<div className="tansaction">
					<p>Transaction</p>
				</div>

				<div className="payouts">
					<div className="display-flex payout">
						<div className="payoutImg"></div>
						<p>Payouts</p>
						<p className="text-success">Rs. 599</p>
					</div>
					<br />
					<div className="display-flex payout">
						<div className="payoutImg"></div>
						<p>Payouts</p>
						<p className="text-success">Rs. 599</p>
					</div>
					<br />
					<div className="display-flex payout">
						<div className="payoutImg"></div>
						<p>Payouts</p>
						<p className="text-success">Rs. 599</p>
					</div>
					<br />
					<div className="display-flex payout">
						<div className="payoutImg"></div>
						<p>Payouts</p>
						<p className="text-success">Rs. 599</p>
					</div>
					<br />
					<div className="display-flex payout">
						<div className="payoutImg"></div>
						<p>Payouts</p>
						<p className="text-success">Rs. 599</p>
					</div>
					<br />
				</div>
			</div>
		</div>
	);
}
export default Wallet;
