import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../components/Hooks/axios";
import DocumentFolderIcon from "../../components/svgCom/DocumentFolderIcon";
import VideoFolderIcon from "../../components/svgCom/VideoFolderIcon";
import CrossIcon from "../../images/CrossIcon.png";

const CreateClass = ({ setShowCreateClass, setReloadUseEffect }) => {
	const [ImageFile, setImageFile] = useState(null);
	const [VideoFile, setVideoFile] = useState(null);
	const [videoDuration, setVideoDuration] = useState(0);
	const [PDFFile, setPDFFile] = useState(null);
	const [imageURL, setImageURL] = useState("");
	const [Title, setTitle] = useState("");
	const [Description, setDescription] = useState("");
	const [Tag, setTag] = useState("");
	const [courseFee, setCourseFee] = useState("")
	const [CourseBtn, setCourseBtn] = useState(false);
	const [uploadPer, setUploadPer] = useState(0);
	const imageRef = useRef();
	const videoRef = useRef();
	const pdfRef = useRef();

	const handelCreateClass = () => {
		if (ImageFile == null) return toast.error("Please add a cover Photo");
		if (Title == "") return toast.error("Please add a title");
		if (Description == "") return toast.error("Please add a description");
		if (Tag == "") return toast.error("Please add a tag");
		if (VideoFile == null) return toast.error("Please add a video");
		if (PDFFile == null) return toast.error("Please add a document");
		setCourseBtn(true);
		const id = toast.loading("Please wait...");

		const formData = new FormData();
		formData.append("courseTitle", Title);
		formData.append("tag", Tag);
		formData.append("courseDescription", Description);
		formData.append("premium", "true");
		formData.append("video", VideoFile);
		formData.append("image", ImageFile);
		formData.append("document", PDFFile);
		formData.append("created_by", localStorage.getItem("user_id") || sessionStorage.getItem("user_id"));
		formData.append("lectureTitle", "Introduction");
		formData.append("lectureDuration", videoDuration);
		formData.append("courseFees", courseFee);

		axios
			.post("/class/createClass", formData, {
				onUploadProgress: (data) => {
					setUploadPer(Math.round((data.loaded / data.total) * 100));
				},
			})
			.then(({ data }) => {
				if (data.status == true) {
					toast.update(id, {
						render: "Class Created Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000,
					});
					setCourseBtn(false);
					setUploadPer(0);
					setImageFile(null);
					setTitle("");
					setDescription("");
					setTag("");
					setVideoFile(null);
					setVideoDuration(0);
					setPDFFile(null);
					setShowCreateClass(false);
					setReloadUseEffect(Math.random());
				} else {
					toast.update(id, {
						render: "Something wrong",
						type: "error",
						isLoading: false,
						autoClose: 2000,
					});
					setCourseBtn(false);
				}
			})
			.catch((error) => {
				toast.update(id, { render: error, type: "error", isLoading: false, autoClose: 2000 });
				setCourseBtn(false);
				console.log("error =: ", error);
			});
	};

	return (
		<div className="CreateClassContainer">
			<div className="CreateClassMain">
				<div className="CreateClassTitle">
					<h1>Create Class</h1>
					<h4>Please fill all the required fields to create class</h4>
					<button onClick={() => setShowCreateClass(false)}>
						<img src={CrossIcon} alt="" />
					</button>
				</div>
				<div className="ContentFields c1" onClick={() => (CourseBtn ? null : imageRef.current.click())}>
					{imageURL ? <img src={imageURL} alt="" /> : <h5>Upload Cover</h5>}
					<input
						type="file"
						accept="image/*"
						ref={imageRef}
						readOnly={CourseBtn}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							setImageFile(file);
							setImageURL(URL.createObjectURL(file));
						}}
					/>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="Course Title"
						value={Title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
				<div className="ContentFields c2">
					<textarea
						readOnly={CourseBtn}
						value={Description}
						placeholder="Description"
						onChange={(e) => setDescription(e.target.value)}></textarea>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="Tag"
						value={Tag}
						onChange={(e) => setTag(e.target.value)}
					/>
				</div>
				<div className="ContentFields">
					<input
						type="text"
						readOnly={CourseBtn}
						placeholder="course Fee"
						value={courseFee}
						onChange={(e) => setCourseFee(e.target.value)}
					/>
				</div>
				<div className="ContentFields c3">
					<button
						type="button"
						onClick={() => (CourseBtn ? null : videoRef.current.click())}
						style={{
							background: VideoFile ? "#471CF1" : "",
							color: VideoFile ? "#fff" : "",
						}}>
						<VideoFolderIcon /> Upload Video
					</button>
					<button
						type="button"
						onClick={() => (CourseBtn ? null : pdfRef.current.click())}
						style={{
							background: PDFFile ? "#471CF1" : "",
							color: PDFFile ? "#fff" : "",
						}}>
						<DocumentFolderIcon /> Upload pdf
					</button>
					{VideoFile || PDFFile ? (
						<button
							type="button"
							onClick={() => (CourseBtn ? null : (setPDFFile(null), setVideoFile([])))}
							style={{
								background: VideoFile || PDFFile ? "#471CF1" : "",
								color: VideoFile || PDFFile ? "#fff" : "",
							}}>
							Clear
						</button>
					) : null}
					<input
						type="file"
						accept="video/*"
						ref={videoRef}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							if (!["mp4"].includes(file.name.split(".")[1].toLowerCase()))
								return toast.error("This type of file is not supported");
							setVideoFile(file);
							let video = document.createElement("video");
							video.src = URL.createObjectURL(file);
							video.onloadedmetadata = function () {
								setVideoDuration(Math.round(video.duration));
							};
							e.target.value = null;
						}}
					/>
					<input
						type="file"
						accept="pdf/*"
						ref={pdfRef}
						style={{ display: "none" }}
						onChange={(e) => {
							let file = e.target.files[0];
							if (["mp4"].includes(file.name.split(".")[1].toLowerCase()))
								return toast.error("This type of file is not supported");
							setPDFFile(file);
							e.target.value = null;
						}}
					/>
				</div>
				<button type="button" disabled={CourseBtn} className="CourseBtn" onClick={handelCreateClass}>
					<span style={{ width: `${uploadPer}%` }}></span>
					<p>{uploadPer != 0 ? `${uploadPer}%` : "Course"}</p>
				</button>
			</div>
		</div>
	);
};

export default CreateClass;
